import React, { useContext, useReducer } from 'react'
import axios from 'axios'
import {
  FleetMgtSvcClient,
  FleetMgtSvcClientV2
} from '@jarvis/web-stratus-client'
import { Stack } from '@jarvis/web-stratus-client'
import { PolicyActions } from 'context/types'
import PoliciesContext from 'context/policies/policiesContext'
import PoliciesReducer from 'context/policies/PoliciesReducer'
import ConfigContext from '../config/configContext'
import { errorProcessor } from 'context/policies/errorProcessor'

const PoliciesProvider = (props) => {
  const { t } = useContext(ConfigContext)
  const initialState = {
    policies: [],
    templates: null,
    selectedPolicy: null,
    clonedPolicy: null,
    devicePolicy: null,
    deviceData: null,
    errorFlag: false
  }

  const [state, dispatch] = useReducer(PoliciesReducer, initialState)
  const {
    stack = Stack.pie,
    stack2,
    demoEnabled,
    apiPath,
    authProvider
  } = props
  const demoUrl = (x) => apiPath('demo', 'generic', 'printMfeCache', x)
  const fleetMgtSvcClient = new FleetMgtSvcClient(stack, authProvider)
  const fleetMgtSvcClientV2 = new FleetMgtSvcClientV2(
    stack2 ?? stack, // stack2 is only used for local testing
    authProvider
  )
  const offset = 0
  const limit = -1

  const getPolicyTemplates = async (displayError?) => {
    displayTemplates(null)
    try {
      const res = demoEnabled
        ? await axios.get(demoUrl('policyTemplates'))
        : await fleetMgtSvcClient.getPolicyTemplates(offset, limit)
      if (res?.status === 200) {
        displayTemplates(res.data?.items || [])
      }
    } catch (error) {
      displayTemplates([])
      displayError
        ? displayError(errorProcessor(error, t).error.message)
        : showError(error)
    }
  }

  const getAllPolicies = async (displayError) => {
    displayPolicies({ items: null })
    try {
      const res = demoEnabled
        ? await axios.get(demoUrl('policies'))
        : await fleetMgtSvcClient.getAllPolicies(offset, limit)
      if (res?.status === 200) {
        displayPolicies(res.data || [])
      }
    } catch (error) {
      displayPolicies({ items: [] })
      displayError(errorProcessor(error, t).error.message)
    }
  }

  const savePolicy = async (policy, displayToast) => {
    try {
      const res = demoEnabled
        ? { status: 200, data: policy }
        : await fleetMgtSvcClient.updatePolicy(policy)
      policyAPICall(res, displayToast)
    } catch (error) {
      showError(error)
    }
  }

  const getPolicy = async (id, redirect, displayError) => {
    try {
      setSelectedPolicy(null)
      const res = demoEnabled
        ? await axios.get(demoUrl('policy'))
        : await fleetMgtSvcClient.getPolicy(id)
      policyAPICall(res)
    } catch (error) {
      if (displayError) {
        displayError(errorProcessor(error, t).error.message)
      } else {
        showError(error)
      }
      if (redirect) {
        redirect()
      }
    }
  }

  const displayTemplates = (payload) => {
    dispatch({ type: PolicyActions.GET_TEMPLATES, payload })
  }

  const displayPolicies = (payload) => {
    dispatch({ type: PolicyActions.GET_ALL_POLICIES, payload })
  }

  const showError = (error) => {
    const payload = errorProcessor(error, t)
    dispatch({ type: PolicyActions.CREATE_HTTP_ERROR, payload })
  }

  const hideError = () => {
    dispatch({ type: PolicyActions.CREATE_HTTP_ERROR_HIDE, payload: null })
  }

  const policyAPICall = (res, displayToast = null) => {
    if (res?.status === 200) {
      setSelectedPolicy(res.data)
      if (displayToast) {
        displayToast()
      }
    }
  }

  const setSelectedPolicy = (payload) => {
    dispatch({ type: PolicyActions.SET_SELECTED_POLICY, payload })
  }

  const removeSelectedPolicyAttribute = (payload) => {
    dispatch({ type: PolicyActions.REMOVE_SELECTED_POLICY_ATTRIBUTE, payload })
  }

  const changeSelectedPolicyAttribute = (payload) => {
    dispatch({ type: PolicyActions.CHANGE_SELECTED_POLICY_ATTRIBUTE, payload })
  }

  const removePolicies = async (policyIds, displayToast) => {
    try {
      if (!demoEnabled) {
        await Promise.all(
          policyIds.map(async (id) => {
            await fleetMgtSvcClient.deletePolicy(id)
          })
        )
      }
      displayToast()
    } catch (error) {
      showError(error)
    }
  }

  const createPolicy = async (policy, displayToast) => {
    try {
      const res = demoEnabled
        ? { status: 201 }
        : await fleetMgtSvcClient.createPolicy(policy)
      if (res?.status === 200 || res?.status === 201) {
        displayToast()
      }
    } catch (error) {
      showError(error)
    }
  }

  const saveDevicePolicy = async (policy, displayToast) => {
    try {
      const res = demoEnabled
        ? { status: 200, data: policy }
        : await fleetMgtSvcClient.updateDevicePolicy(policy)
      policyAPICall(res, displayToast)
    } catch (error) {
      showError(error)
    }
  }

  const getDevicePolicy = async (deviceID, displayError) => {
    try {
      setSelectedPolicy(null)
      const res = demoEnabled
        ? await axios.get(demoUrl('devicePolicy'))
        : await fleetMgtSvcClient.getDevicePolicy(deviceID)
      policyAPICall(res)
    } catch (error) {
      setSelectedPolicy({ attributes: [] })
      if (error?.response.status !== 404) {
        displayError(errorProcessor(error, t).error.message)
      }
    }
  }

  const removeDevicePolicy = async (deviceID, displayToast) => {
    try {
      if (!demoEnabled) {
        await fleetMgtSvcClient.deleteDevicePolicy(deviceID)
        // Set lastModifiedAt for retrieving newer compliance data
        setSelectedPolicy({
          attributes: [],
          lastModifiedAt: state.deviceData?.lastRunAt + 1
        })
      }
      displayToast()
    } catch (error) {
      showError(error)
    }
  }

  const getDeviceData = (payload) => {
    dispatch({ type: PolicyActions.GET_DEVICE_DATA, payload })
  }

  const getDeviceDetails = async (deviceId: string, displayError) => {
    try {
      getDeviceData(null)
      const res = demoEnabled
        ? await axios.get(demoUrl('deviceCompliance'))
        : await fleetMgtSvcClientV2.getDevice(deviceId)
      if (res?.status === 200) {
        getDeviceData(res.data)
      }
    } catch (error) {
      getDeviceData({ policies: [] })
      if (error?.response?.status !== 404) {
        displayError(errorProcessor(error, t).error.message)
      }
    }
  }

  const triggerAssessment = async (deviceId: string, displayToast) => {
    try {
      const res = demoEnabled
        ? { status: 204 }
        : await fleetMgtSvcClient.triggerAssessments([deviceId])
      if (res?.status === 204) {
        displayToast()
      }
    } catch (error) {
      displayToast(errorProcessor(error, t).error.message)
    }
  }

  return (
    <PoliciesContext.Provider
      value={{
        policies: state.policies,
        templates: state.templates,
        selectedPolicy: state.selectedPolicy,
        devicePolicy: state.devicePolicy,
        clonedPolicy: state.clonedPolicy,
        fleetMgtSvcClient: fleetMgtSvcClient,
        fleetMgtSvcClientV2: fleetMgtSvcClientV2,
        removeSelectedPolicyAttribute,
        changeSelectedPolicyAttribute,
        showError,
        setSelectedPolicy,
        getAllPolicies,
        saveDevicePolicy,
        savePolicy,
        getPolicy,
        getDevicePolicy,
        removeDevicePolicy,
        error: state.error,
        hideError,
        errorFlag: state.errorFlag,
        removePolicies,
        createPolicy,
        deviceData: state.deviceData,
        getDeviceDetails,
        triggerAssessment,
        getPolicyTemplates
      }}
    >
      {props.children}
    </PoliciesContext.Provider>
  )
}

export default PoliciesProvider
