import React, { useState } from 'react'
import { Button, ButtonGroup, Modal } from '@veneer/core'
import 'components/policies/modal/index.scss'
import 'styles/global.scss'
import { TextBlack } from 'styles/styles'
import DropboxItems from 'context/policies/dropboxItems/DropboxItems'
import LabelTextBox from 'common/controls/labelTextBox'
import LabelRadioButtons from 'common/controls/labelRadioButtons'
import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'

const RestrictColorModal = (props) => {
  const [applicationName, setApplicationName] = useState('')
  const [permission, setPermission] = useState(
    `${PolicyAttributeEnum.Restrict_Color}.app-perm.allow`
  )
  const { onClose, onAdd, existingApplicationNames, getLocalized } = props
  const [errorMessage, setErrorMessage] = useState('')

  const updateApplicationName = (value): boolean => {
    if (existingApplicationNames.includes(value)) {
      setErrorMessage(getLocalized('application-name-already-exists-error'))
    } else if (!value) {
      setErrorMessage(getLocalized('application-name-required-error'))
    } else if (value.trim() !== value) {
      setErrorMessage(getLocalized('application-name-no-space-error'))
    } else {
      setErrorMessage('')
      return true
    }
    return false
  }

  return (
    <Modal
      onClose={onClose}
      closeOnBlur={false}
      show={true}
      title={getLocalized('common.add')}
      className={'small-policy-modal'}
      data-testid={'id-restrict-color-modal'}
      footer={
        <ButtonGroup>
          <Button
            onClick={() => {
              if (updateApplicationName(applicationName)) {
                onAdd({ name: applicationName, permission })
                onClose()
              }
            }}
          >
            {getLocalized('common.add')}
          </Button>
          <Button appearance={'secondary'} onClick={onClose}>
            {getLocalized('common.cancel')}
          </Button>
        </ButtonGroup>
      }
    >
      <TextBlack className={'paddingBottom16'}>
        {getLocalized('description_message')}
      </TextBlack>

      <LabelTextBox
        label={getLocalized('application_name')}
        required={true}
        error={!!errorMessage}
        helperText={errorMessage}
        placeholder={getLocalized('enter-application-name_text')}
        value={applicationName || ''}
        onChange={(value) => {
          updateApplicationName(value)
          setApplicationName(value)
        }}
        id={'restrict-color-application_name'}
        className={'paddingBottom16 maxTextWidth'}
      />

      <LabelRadioButtons
        label={getLocalized('permission')}
        value={permission}
        onChange={(_, option) => setPermission(option)}
        id={'restrict-color-radiobutton_permission'}
        options={DropboxItems.restrictDefaultAppParams.map((i) => {
          return { value: i.value, label: getLocalized(i.label) }
        })}
      />
    </Modal>
  )
}
export default RestrictColorModal
