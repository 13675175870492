import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'
import { Item, ItemEx } from 'context/policies/PoliciesConfiguration'
import PoliciesErrors from 'context/policies/PoliciesErrors'

export default class DropboxItems {
  static snmpPermissions: Item[] = [
    {
      value: `${PolicyAttributeEnum.SNMP_V1_V2}.permission.read-only`,
      label: 'permission_read-only'
    },
    {
      value: `${PolicyAttributeEnum.SNMP_V1_V2}.permission.read-write`,
      label: 'permission_read-write'
    },
    {
      value: `${PolicyAttributeEnum.SNMP_V1_V2}.permission.disable`,
      label: 'permission_disable'
    }
  ]
  static snmpEncryptionAlgorithms: Item[] = [
    {
      value: `${PolicyAttributeEnum.SNMP_V3}.encryption-algorithm.md5`,
      label: 'encryption-algorithm_md5'
    },
    {
      value: `${PolicyAttributeEnum.SNMP_V3}.encryption-algorithm.sha1`,
      label: 'encryption-algorithm_sha1'
    }
  ]

  static diskEncryptionStatuses: Item[] = [
    {
      value: `${PolicyAttributeEnum.Disk_Encryption_Status}.status.active`,
      label: 'status-active'
    },
    {
      value: `${PolicyAttributeEnum.Disk_Encryption_Status}.status.inactive`,
      label: 'status-inactive'
    }
  ]

  static fileEraseModes: Item[] = [
    {
      value: `${PolicyAttributeEnum.File_Erase_Mode}.mode.non-secure`,
      label: 'mode_non-secure'
    },
    {
      value: `${PolicyAttributeEnum.File_Erase_Mode}.mode.fast`,
      label: 'mode_fast'
    },
    {
      value: `${PolicyAttributeEnum.File_Erase_Mode}.mode.sanitize`,
      label: 'mode_sanitize'
    }
  ]

  static ctrlPanelLockOptions: Item[] = [
    {
      value: `${PolicyAttributeEnum.Control_Panel_Lock}.option.moderate`,
      label: 'option_moderate'
    },
    {
      value: `${PolicyAttributeEnum.Control_Panel_Lock}.option.unlock`,
      label: 'option_unlock'
    }
  ]

  static dateFormats: Item[] = [
    {
      value: `${PolicyAttributeEnum.Date_Time_Format}.date.mmmddyyyy`,
      label: 'date-mmmddyyyy'
    },
    {
      value: `${PolicyAttributeEnum.Date_Time_Format}.date.ddmmmyyyy`,
      label: 'date-ddmmmyyyy'
    },
    {
      value: `${PolicyAttributeEnum.Date_Time_Format}.date.yyyymmmdd`,
      label: 'date-yyyymmmdd'
    }
  ]

  static timeFormats: Item[] = [
    {
      value: `${PolicyAttributeEnum.Date_Time_Format}.time.hr12`,
      label: 'time-hr12'
    },
    {
      value: `${PolicyAttributeEnum.Date_Time_Format}.time.hr24`,
      label: 'time-hr24'
    }
  ]

  static ewsLanguageModes: Item[] = [
    {
      value: `${PolicyAttributeEnum.Ews_Language}.mode.select`,
      label: 'mode-select'
    },
    {
      value: `${PolicyAttributeEnum.Ews_Language}.mode.browser`,
      label: 'mode-browser'
    },
    {
      value: `${PolicyAttributeEnum.Ews_Language}.mode.printer`,
      label: 'mode-printer'
    }
  ]

  static latestFwSources: Item[] = [
    {
      value: `${PolicyAttributeEnum.Check_Latest_FW}.file-source.web`,
      label: 'file-source_web'
    }
  ]

  static timeServicesCodes: Item[] = [
    {
      value: `${PolicyAttributeEnum.Time_Services}.drift-correction.none`,
      label: 'drift-correction-none'
    },
    {
      value: `${PolicyAttributeEnum.Time_Services}.drift-correction.hp`,
      label: 'drift-correction-hp'
    },
    {
      value: `${PolicyAttributeEnum.Time_Services}.drift-correction.ntp`,
      label: 'drift-correction-ntp'
    }
  ]

  static inputAutoContinueCodes: Item[] = [
    {
      value: `${PolicyAttributeEnum.Input_Auto_Continue}.mode.cancel-job`,
      label: 'cancel-job'
    },
    {
      value: `${PolicyAttributeEnum.Input_Auto_Continue}.mode.override-size`,
      label: 'override-size'
    },
    {
      value: `${PolicyAttributeEnum.Input_Auto_Continue}.mode.override-type`,
      label: 'override-type'
    },
    {
      value: `${PolicyAttributeEnum.Input_Auto_Continue}.mode.override-size-type`,
      label: 'override-size-type'
    }
  ]

  static googleCloudPrintModes: Item[] = [
    {
      value: `${PolicyAttributeEnum.Google_Cloud_Print}.mode.enable`,
      label: 'mode-enable'
    },
    {
      value: `${PolicyAttributeEnum.Google_Cloud_Print}.mode.disable`,
      label: 'mode-disable'
    },
    {
      value: `${PolicyAttributeEnum.Google_Cloud_Print}.mode.remove`,
      label: 'mode-remove'
    }
  ]

  static tcpipConfigModes: Item[] = [
    {
      value: `${PolicyAttributeEnum.Tcpip_Config}.mode.bootp`,
      label: 'mode-bootp'
    },
    {
      value: `${PolicyAttributeEnum.Tcpip_Config}.mode.dhcp`,
      label: 'mode-dhcp'
    },
    {
      value: `${PolicyAttributeEnum.Tcpip_Config}.mode.auto-ip`,
      label: 'mode-auto-ip'
    }
  ]

  static useRequestedTrayModes: Item[] = [
    {
      value: `${PolicyAttributeEnum.Use_Requested_Tray}.mode.exclusively`,
      label: 'mode-exclusively'
    },
    {
      value: `${PolicyAttributeEnum.Use_Requested_Tray}.mode.first`,
      label: 'mode-first'
    }
  ]

  static manualFeedModes: Item[] = [
    {
      value: `${PolicyAttributeEnum.Manual_Feed}.mode.cassette`,
      label: 'mode-cassette'
    },
    {
      value: `${PolicyAttributeEnum.Manual_Feed}.mode.first`,
      label: 'mode-first'
    }
  ]

  static manualFeedPromptModes: Item[] = [
    {
      value: `${PolicyAttributeEnum.Manual_Feed_Prompt}.mode.always`,
      label: 'mode-always'
    },
    {
      value: `${PolicyAttributeEnum.Manual_Feed_Prompt}.mode.unless-loaded`,
      label: 'mode-unless-loaded'
    }
  ]

  static overwriteModes = (id): Item[] => [
    { value: `${id}.mode.overwrite`, label: 'mode-overwrite' },
    { value: `${id}.mode.append`, label: 'mode-append' }
  ]

  static ldapSetupCredentialModes: Item[] = [
    {
      value: `${PolicyAttributeEnum.Ldap_Setup}.credential.device-user`,
      label: 'device-user'
    },
    {
      value: `${PolicyAttributeEnum.Ldap_Setup}.credential.admin`,
      label: 'admin'
    }
  ]

  static restrictColorModes: Item[] = [
    {
      value: `${PolicyAttributeEnum.Restrict_Color}.mode.enable`,
      label: 'mode-enable'
    },
    {
      value: `${PolicyAttributeEnum.Restrict_Color}.mode.disable`,
      label: 'mode-disable'
    },
    {
      value: `${PolicyAttributeEnum.Restrict_Color}.mode.custom-access`,
      label: 'mode-custom-access'
    }
  ]

  static restrictDefaultAppParams: Item[] = [
    {
      value: `${PolicyAttributeEnum.Restrict_Color}.app-perm.allow`,
      label: 'def-app-perm-enable'
    },
    {
      value: `${PolicyAttributeEnum.Restrict_Color}.app-perm.remove`,
      label: 'def-app-perm-remove'
    }
  ]

  static userAuthenticationModes: Item[] = [
    {
      value: `${PolicyAttributeEnum.Smtp_Gateway}.credential.user`,
      label: 'credential-user'
    },
    {
      value: `${PolicyAttributeEnum.Smtp_Gateway}.credential.public`,
      label: 'credential-public'
    }
  ]

  static autoSendItems: ItemEx[] = [
    {
      value: `${PolicyAttributeEnum.Auto_Send}.every.unit.days`,
      label: 'every-unit-days',
      hint: 'every-unit-days_hint',
      errors: PoliciesErrors.errorsIntegerRange(1, 28)
    },
    {
      value: `${PolicyAttributeEnum.Auto_Send}.every.unit.weeks`,
      label: 'every-unit-weeks',
      hint: 'every-unit-weeks_hint',
      errors: PoliciesErrors.errorsIntegerRange(1, 4)
    },
    {
      value: `${PolicyAttributeEnum.Auto_Send}.every.unit.months`,
      label: 'every-unit-months',
      hint: 'every-unit-months_hint',
      errors: PoliciesErrors.errorsIntegerRange(1, 6)
    },
    {
      value: `${PolicyAttributeEnum.Auto_Send}.every.unit.pages`,
      label: 'every-unit-pages',
      hint: 'every-unit-pages_hint',
      errors: PoliciesErrors.errorsIntegerRange(50, 30000)
    }
  ]

  static homeScreenWallpaperModes: Item[] = [
    {
      value: `${PolicyAttributeEnum.Home_Screen_Wallpaper}.mode.clear`,
      label: 'mode-clear'
    },
    {
      value: `${PolicyAttributeEnum.Home_Screen_Wallpaper}.mode.custom`,
      label: 'mode-custom'
    }
  ]

  static homeScreenTextEffects: Item[] = [
    {
      value: `${PolicyAttributeEnum.Home_Screen_Wallpaper}.effect.none`,
      label: 'effect-none'
    },
    {
      value: `${PolicyAttributeEnum.Home_Screen_Wallpaper}.effect.shadow`,
      label: 'effect-shadow'
    },
    {
      value: `${PolicyAttributeEnum.Home_Screen_Wallpaper}.effect.background`,
      label: 'effect-solid'
    }
  ]

  static ipv6DhcpPolicyCodes: Item[] = [
    {
      value: `${PolicyAttributeEnum.Ipv6_Info}.dhcp-policy.always`,
      label: 'dhcp_always'
    },
    {
      value: `${PolicyAttributeEnum.Ipv6_Info}.dhcp-policy.unsuccessfull`,
      label: 'dhcp_unsuccessfull'
    },
    {
      value: `${PolicyAttributeEnum.Ipv6_Info}.dhcp-policy.requested`,
      label: 'dhcp_requested'
    }
  ]

  static consumableAccessCtrlThresholds: Item[] = [
    {
      value: `${PolicyAttributeEnum.Consumable_Access_Ctrl}.threshold.normal`,
      label: 'threshold_normal'
    },
    {
      value: `${PolicyAttributeEnum.Consumable_Access_Ctrl}.threshold.delayed`,
      label: 'threshold_delayed'
    }
  ]

  static controlPanelLogoutModes: Item[] = [
    {
      value: `${PolicyAttributeEnum.Control_Panel_Logout_Policy}.mode.force`,
      label: 'mode-force'
    },
    {
      value: `${PolicyAttributeEnum.Control_Panel_Logout_Policy}.mode.none`,
      label: 'mode-none'
    },
    {
      value: `${PolicyAttributeEnum.Control_Panel_Logout_Policy}.mode.reminder`,
      label: 'mode-reminder'
    }
  ]

  static tpmStatusModes: Item[] = [
    {
      value: `${PolicyAttributeEnum.Tpm_Status}.mode.none`,
      label: 'mode-none'
    },
    {
      value: `${PolicyAttributeEnum.Tpm_Status}.mode.disabled`,
      label: 'mode-disabled'
    },
    {
      value: `${PolicyAttributeEnum.Tpm_Status}.mode.enabled`,
      label: 'mode-enabled'
    }
  ]

  static secureDiskPasswordModes: Item[] = [
    {
      value: `${PolicyAttributeEnum.Secure_Disk_Password}.mode.random`,
      label: 'mode-random'
    },
    {
      value: `${PolicyAttributeEnum.Secure_Disk_Password}.mode.manual`,
      label: 'mode-manual'
    }
  ]

  static sipServerMethods: Item[] = [
    {
      value: `${PolicyAttributeEnum.Sip_Server_Settings}.method.udp`,
      label: 'method-udp'
    },
    {
      value: `${PolicyAttributeEnum.Sip_Server_Settings}.method.tcp`,
      label: 'method-tcp'
    },
    {
      value: `${PolicyAttributeEnum.Sip_Server_Settings}.method.tls`,
      label: 'method-tls'
    }
  ]
}
