import React, { useContext, useEffect, useState, useMemo, memo } from 'react'
import { Button, Table } from '@veneer/core'
import { ColumnIndexTypes } from '@veneer/core/dist/scripts/table'
import settingsContext from 'context/settings/settingsContext'
import configContext from 'context/config/configContext'
import { ObjectAttributeDescription } from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import { FlexRow, TextBlack, MaxTableHeight, FlexColumn } from 'styles/styles'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import HelpButton from 'components/policies/settings/attributes/HelpButton'
import WebEncryptionModal, {
  WebEncryptionEnum,
  CipherColumnsEnum
} from './WebEncryptionModal'
import WarningMessage from '../WarningMessage'
import { MessageTypesEnum } from 'context/policies/PoliciesErrors'
import WebEncryptionItems from 'context/policies/dropboxItems/WebEncryptionItems'
import { jsonParse, TABLE_CONSTANTS } from 'common/utilities'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'

const tablePreferences = {
  width: [
    { columnId: CipherColumnsEnum.NAME, width: 300 },
    { columnId: CipherColumnsEnum.VERSIONS, width: 192 },
    { columnId: CipherColumnsEnum.ALGORITHM, width: 120 }
  ]
}

const WebEncryptionControl = (props) => {
  const description: ObjectAttributeDescription = props.description
  const {
    id,
    attributes,
    localizationPath,
    data: { deviceSettings },
    onAttributeChange
  } = props
  const { attribute, constraints } = description
  const { tt } = useContext(configContext)
  const {
    isEnabled,
    addDisabled,
    removeDisabled,
    addError,
    removeError,
    displayAllErrors
  } = useContext(settingsContext)

  const getLocalized = (key: string, params?): string =>
    tt(localizationPath, key, params)

  const [showModal, setShowModal] = useState(false)
  const [errors, setErrors] = useState([])
  const [displayError, setDisplayError] = useState(displayAllErrors)

  const { value, compliance } = useMemo(() => {
    const val = PoliciesHelper.getData(description, deviceSettings)
    val[WebEncryptionEnum.CIPHERS] = jsonParse(val[WebEncryptionEnum.CIPHERS])
    const comp = PoliciesHelper.getCompliance(description, deviceSettings)
    return { value: val, compliance: comp }
  }, [deviceSettings])
  const ciphers = value[WebEncryptionEnum.CIPHERS]

  const items = useMemo(() => {
    const arr = WebEncryptionItems.webEncryptionCiphers(constraints)
    const makeMap = (y) =>
      y.reduce((acc, x) => {
        acc[x.value] = x
        return acc
      }, {})
    return {
      ciphersMap: makeMap(WebEncryptionItems.ciphers),
      filteredCiphersMap: constraints ? makeMap(arr) : null,
      tlsMinVersions: WebEncryptionItems.tlsMinVersions(constraints),
      tlsMaxVersions: WebEncryptionItems.tlsMaxVersions(constraints),
      ciphersEcdsa: arr.some((x) => WebEncryptionItems.isEcdsa(x['type']))
    }
  }, [constraints])

  // Remove unsupported ciphers
  useEffect(() => {
    if (onAttributeChange && items?.filteredCiphersMap) {
      const newValue = {}
      const findVer = (x, y) => x.some((z) => z.value === y)
      if (!findVer(items.tlsMinVersions, value[WebEncryptionEnum.TLS_MIN])) {
        newValue[WebEncryptionEnum.TLS_MIN] = items.tlsMinVersions[0].value
      }
      if (!findVer(items.tlsMaxVersions, value[WebEncryptionEnum.TLS_MAX])) {
        newValue[WebEncryptionEnum.TLS_MAX] =
          items.tlsMaxVersions[items.tlsMaxVersions.length - 1].value
      }
      const initial = value[WebEncryptionEnum.CIPHERS] as unknown as string[]
      const filtered = initial.filter((x) => items.filteredCiphersMap[x])
      if (filtered.length !== initial.length) {
        newValue[WebEncryptionEnum.CIPHERS] = filtered
      }
      if (Object.keys(newValue).length) {
        onChange({ ...value, ...newValue })
      }
    }
  }, [value, items])

  const getErrors = (val) => {
    if (!val[WebEncryptionEnum.CIPHERS].length) {
      return ['not-selected-error']
    }
    const rsa = []
    const ecdsa = []
    const other = []
    val[WebEncryptionEnum.CIPHERS].forEach((x) => {
      const cipher = items.ciphersMap[x]
      if (cipher) {
        const vers = WebEncryptionItems.isEcdsa(cipher.type)
          ? ecdsa
          : WebEncryptionItems.isRsa(cipher.type)
          ? rsa
          : other
        cipher.versions.forEach((y) => {
          vers[y] = true
        })
      }
    })

    const newErrors = []

    // RSA and optionally ECDSA cipher types are required
    if (!rsa.length || (items.ciphersEcdsa && !ecdsa.length)) {
      newErrors.push('network-identity-error')
    }

    // TLS 1.3 should be supported by GCM or CCM ciphers
    let maxVer = +val[WebEncryptionEnum.TLS_MAX].slice(-1)
    if (maxVer === 3) {
      if (!other[maxVer]) {
        newErrors.push('invalid-combination-error')
        return newErrors
      }
      maxVer--
    }

    // RSA and optionally ECDSA ciphers are required for every of selected TLS versions in range 1.0-1.2
    const minVer = +val[WebEncryptionEnum.TLS_MIN].slice(-1)
    for (let i = minVer; i <= maxVer; i++) {
      if (!rsa[i] || (items.ciphersEcdsa && !ecdsa[i])) {
        newErrors.push('invalid-combination-error')
        break
      }
    }

    return newErrors
  }

  const enabled = isEnabled(attribute)
  const showError = displayError || displayAllErrors

  useEffect(() => {
    const errs = enabled ? getErrors(value) : []
    setErrors(showError ? errs : [])
    errs.length
      ? addError(id, attribute, showError)
      : removeError(id, attribute)
  }, [items, value, showError])

  useEffect(() => {
    if (attributes) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  const tableColumns = useMemo(() => {
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id: TABLE_CONSTANTS.ID, label: TABLE_CONSTANTS.ID, index },
      { id: CipherColumnsEnum.NAME, label: getLocalized('ciphers-name') },
      {
        id: CipherColumnsEnum.VERSIONS,
        label: getLocalized('ciphers-versions')
      },
      {
        id: CipherColumnsEnum.ALGORITHM,
        label: getLocalized('ciphers-algorithm')
      }
    ]
  }, [])

  const tableItem = (x) => {
    const versions = x.versions.map((x) => `TLS 1.${x}`).join(', ')
    return {
      [TABLE_CONSTANTS.ID]: x.value,
      [CipherColumnsEnum.NAME]: getLocalized(x.label),
      [CipherColumnsEnum.VERSIONS]: versions,
      [CipherColumnsEnum.ALGORITHM]: getLocalized('algorithm-' + x.type)
    }
  }

  const tableData = useMemo(
    () =>
      WebEncryptionItems.ciphers.reduce((acc, x) => {
        if (ciphers.includes(x.value)) {
          acc.push(tableItem(x))
        }
        return acc
      }, []),
    [ciphers]
  )

  const onChange = (v) => {
    const deviceSettings = [...props.data.deviceSettings]
    PoliciesHelper.setData(description, deviceSettings, {
      ...v,
      [WebEncryptionEnum.CIPHERS]: JSON.stringify(v[WebEncryptionEnum.CIPHERS])
    })
    onAttributeChange({ ...props.data, deviceSettings })
  }

  const tlsVersion = (ver) => (
    <PreviewItem
      label={`${localizationPath}.${ver}`}
      value={getLocalized(
        WebEncryptionItems.tlsVersions.find((x) => x.value === value[ver])
          ?.label
      )}
      compliance={compliance[ver]}
      className={'marginBottom16'}
    />
  )

  return (
    <FlexColumn>
      {tlsVersion(WebEncryptionEnum.TLS_MIN)}
      {tlsVersion(WebEncryptionEnum.TLS_MAX)}

      <FlexRow className={'alignCenter marginBottom12'}>
        <TextBlack>{getLocalized('ciphers')}</TextBlack>
        <FlexRow className={'paddingLeft4 lineBreak'}>
          <HelpButton
            enabled={enabled}
            description={getLocalized('ciphers_help')}
          />
        </FlexRow>
      </FlexRow>

      {onAttributeChange && (
        <FlexRow className={'alignCenter marginBottom12'}>
          <Button
            appearance={'secondary'}
            disabled={!enabled}
            onClick={() => setShowModal(true)}
          >
            {getLocalized(ciphers.length ? 'ciphers-change' : 'ciphers-select')}
          </Button>
          <TextBlack className={'marginLeft12'} disabled={!enabled}>
            {getLocalized('ciphers-selected', { count: ciphers.length })}
          </TextBlack>
        </FlexRow>
      )}

      <MaxTableHeight>
        <Table
          columns={tableColumns}
          data={tableData}
          className={'widthColAuto'}
          data-testid={'id-web-encryption-table'}
          preferences={tablePreferences}
          i18n={Retrievei18nItems()}
        />
      </MaxTableHeight>

      {errors &&
        errors.map((error) => (
          <WarningMessage
            key={error}
            type={MessageTypesEnum.ERROR}
            message={getLocalized(error)}
          />
        ))}

      {compliance && (
        <PreviewItem
          compliance={compliance[WebEncryptionEnum.CIPHERS]}
          className={'marginTop4'}
        />
      )}

      {showModal && (
        <WebEncryptionModal
          edit={!!ciphers.length}
          value={value}
          constraints={constraints}
          getErrors={getErrors}
          getLocalized={getLocalized}
          tableData={{ tableColumns, tablePreferences }}
          onClose={() => setShowModal(false)}
          onChange={(v) => {
            setDisplayError(true)
            onChange(v)
          }}
        />
      )}
    </FlexColumn>
  )
}

export default memo(WebEncryptionControl)
