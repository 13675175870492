import React, { useState, useEffect } from 'react'
import { Button, ButtonGroup, Checkbox, Modal } from '@veneer/core'
import 'components/policies/modal/index.scss'
import 'styles/global.scss'
import { FlexColumn, FlexRow, TextBlack } from 'styles/styles'
import LabelTextBox from 'common/controls/labelTextBox'
import LabelSelect from 'common/controls/labelSelect'
import LabelRadioButtons from 'common/controls/labelRadioButtons'
import { getError } from 'common/utilities'
import PoliciesErrors from 'context/policies/PoliciesErrors'
import copyStampItems from './CopyStampsItems'
import ColorItems from 'context/policies/dropboxItems/ColorItems'

export enum CopyStampEnum {
  POSITION = 'position',
  CONTENT = 'content',
  WHITE_BG = 'whiteBg',
  START = 'start',
  FONT = 'font',
  SIZE = 'size',
  COLOR = 'color'
}

const StampEditModal = (props) => {
  const { value, getLocalized } = props
  const position = value[CopyStampEnum.POSITION]
  const [errorStartingNumber, setErrorStartingNumber] = useState(null)
  const [stampContents, setStampContents] = useState(
    value[CopyStampEnum.CONTENT] || []
  )
  const [startingNumber, setStartingNumber] = useState(
    value[CopyStampEnum.START]
  )
  const [textFont, setTextFont] = useState(
    value[CopyStampEnum.FONT] || copyStampItems.textFonts[0].value // def radio
  )
  const [textSize, setTextSize] = useState(
    value[CopyStampEnum.SIZE] || copyStampItems.textSizes[0].value // def radio
  )
  const [whiteBg, setWhiteBg] = useState(!!value[CopyStampEnum.WHITE_BG])
  const [textColor, setTextColor] = useState([value[CopyStampEnum.COLOR]])
  const [textColorError, setTextColorError] = useState(null)
  const [saveDisabled, setSaveDisabled] = useState(true)

  useEffect(() => {
    const data = {
      [CopyStampEnum.POSITION]: position,
      [CopyStampEnum.CONTENT]: stampContents,
      [CopyStampEnum.START]: startingNumber,
      [CopyStampEnum.FONT]: textFont,
      [CopyStampEnum.SIZE]: textSize,
      [CopyStampEnum.COLOR]: textColor[0],
      [CopyStampEnum.WHITE_BG]: whiteBg
    }
    setSaveDisabled(JSON.stringify(value) === JSON.stringify(data))
  }, [
    position,
    stampContents,
    startingNumber,
    textFont,
    textSize,
    textColor,
    whiteBg,
    value
  ])

  const saveData = () => {
    const errorStartingNumber = getError(
      PoliciesErrors.errorsStartingNumber,
      startingNumber
    )
    const errorTextColor = !textColor[0]
      ? { message: 'common.errors.not-selected' }
      : null
    setErrorStartingNumber(errorStartingNumber)
    setTextColorError(errorTextColor)
    const data = {
      [CopyStampEnum.POSITION]: position,
      [CopyStampEnum.CONTENT]: stampContents,
      [CopyStampEnum.START]: startingNumber,
      [CopyStampEnum.FONT]: textFont,
      [CopyStampEnum.SIZE]: textSize,
      [CopyStampEnum.COLOR]: textColor[0],
      [CopyStampEnum.WHITE_BG]: whiteBg
    }
    if (
      stampContents?.length === 0 ||
      (!errorStartingNumber?.message && !errorTextColor?.message)
    ) {
      props.onChange(data)
      props.onClose()
    }
  }

  const buildTextColorOptions = (items) => {
    return items.map((v) => {
      return {
        value: v.value,
        label: getLocalized(`${v.label}`)
      }
    })
  }

  const onChangeCheckBoxItems = (target) => {
    const id = target.id
    if (checkEnabled(id)) {
      const stampContentsArray = [...stampContents]
      const index = stampContents?.indexOf(id)
      stampContentsArray?.splice(index, 1)
      setStampContents(stampContentsArray)
    } else {
      setStampContents([...stampContents, id])
    }
  }

  const positionText = copyStampItems.stampPositions.find(
    (item) => item.value === position
  ).label

  const checkEnabled = (value) => stampContents?.includes(value)
  return (
    <Modal
      onClose={props.onClose}
      closeOnBlur={false}
      show={true}
      title={getLocalized('set-stamp')}
      className={'small-policy-modal'}
      data-testid={'id-stamp-edit-modal'}
      footer={
        <ButtonGroup>
          <Button disabled={saveDisabled} onClick={saveData}>
            {getLocalized('common.save')}
          </Button>
          <Button appearance={'secondary'} onClick={props.onClose}>
            {getLocalized('common.cancel')}
          </Button>
        </ButtonGroup>
      }
    >
      <TextBlack className={'paddingBottom16'}>
        {getLocalized('description-message')}
      </TextBlack>

      <TextBlack className={'paddingBottom16'}>
        {getLocalized('stamp-position')}: <b>{getLocalized(positionText)}</b>
      </TextBlack>

      <TextBlack className={'paddingBottom16'}>
        {getLocalized('stamp-content')}
      </TextBlack>
      <FlexColumn>
        {copyStampItems.stampContents.map((content) => (
          <FlexRow className={'paddingBottom16'} key={content.label}>
            <Checkbox
              id={content.value}
              label={getLocalized(content.label)}
              checked={checkEnabled(content.value)}
              onChange={({ target }) => {
                onChangeCheckBoxItems(target)
              }}
            />
          </FlexRow>
        ))}
      </FlexColumn>

      <LabelTextBox
        label={getLocalized('stamp-start')}
        required={true}
        value={startingNumber || ''}
        placeholder={getLocalized('stamp-start')}
        id={'starting-number'}
        error={!!errorStartingNumber}
        helperText={
          errorStartingNumber
            ? getLocalized(
                errorStartingNumber?.message,
                errorStartingNumber?.params
              )
            : undefined
        }
        onChange={(v) => {
          setStartingNumber(v)
          setErrorStartingNumber(
            getError(PoliciesErrors.errorsStartingNumber, v)
          )
        }}
        className={'midTextWidth paddingBottom16'}
      />

      <LabelRadioButtons
        label={getLocalized('stamp-font')}
        value={textFont}
        id={'radioButton_text_font'}
        className={'paddingBottom16'}
        onChange={(_, option) => {
          setTextFont(option)
        }}
        options={copyStampItems.textFonts.map((i) => {
          return { value: i.value, label: getLocalized(i.label) }
        })}
      />

      <LabelRadioButtons
        label={getLocalized('text-size')}
        value={textSize}
        id={'radiobutton_text_size'}
        className={'paddingBottom16'}
        onChange={(_, option) => {
          setTextSize(option)
        }}
        options={copyStampItems.textSizes.map((i) => {
          return { value: i.value, label: getLocalized(i.label) }
        })}
      />

      <LabelSelect
        label={getLocalized('text-color')}
        placeholder={getLocalized('common.select-option')}
        className={'paddingBottom16 maxTextWidth'}
        value={textColor}
        error={!!textColorError}
        helperText={
          textColorError?.message &&
          getLocalized(textColorError?.message, textColorError?.params)
        }
        id={'radioButton_text_color'}
        options={buildTextColorOptions(ColorItems.stampColors)}
        onChange={({ value: v }) => {
          const error = !v ? { message: 'common.errors.not-selected' } : null
          setTextColorError(error)
          setTextColor([v])
        }}
      />

      <Checkbox
        id={'whiteBg'}
        label={getLocalized('stamp-white-bg')}
        checked={whiteBg}
        onChange={({ target }) => {
          setWhiteBg(target.checked)
        }}
      />
    </Modal>
  )
}
export default StampEditModal
