import React, { useContext, useEffect, useState, memo } from 'react'
import configContext from 'context/config/configContext'
import { Checkbox } from '@veneer/core'
import LabelSelect from 'common/controls/labelSelect'
import { isControlDisabled } from 'common/securityControlHandler'
import HelpButton from 'components/policies/settings/attributes/HelpButton'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import { isAdvanced, isHpsm } from 'common/utilities'
import { FlexRow } from 'styles/styles'
import 'styles/global.scss'

enum PolicySeverityEnum {
  LOW = 'low',
  MODERATE = 'moderate',
  HIGH = 'high'
}

enum PolicySecurityToggleEnum {
  UNSUPPORTED = 'unsupported',
  UNENTITLED = 'unentitled',
  REMEDIATION = 'remediation'
}

const SecurityAttributes = (props) => {
  const { t } = useContext(configContext)

  const data = props.attribute.metadata
  const [metadata, setMetadata] = useState(data)

  const remediationDisabled = isControlDisabled(
    PolicySecurityToggleEnum.REMEDIATION,
    props.attribute.name
  )

  const advanced = isAdvanced(metadata.entitlements)
  const severity = isHpsm(metadata.entitlements)

  useEffect(() => {
    setMetadata(data)
  }, [data])

  const onToggleChange = (name, value) => {
    const newMetadata = { ...metadata }
    newMetadata[name] = value
    setMetadata(newMetadata)
    props.onChange({ ...props.attribute, metadata: newMetadata })
  }

  const key = (subkey: string): string => {
    return `policy.settings.security.${subkey}`
  }

  const testId = (subkey: string): string => {
    return `${props.attribute.name}.${subkey}`
  }

  const onSeverityChange = (selectedOption) => {
    const newMetadata = {
      ...metadata,
      severity: selectedOption.value
    }
    setMetadata(newMetadata)
    props.onChange({ ...props.attribute, metadata: newMetadata })
  }

  const severityOptions = () => {
    return [
      {
        value: PolicySeverityEnum.LOW,
        label: t(key('severity.low'))
      },
      {
        value: PolicySeverityEnum.MODERATE,
        label: t(key('severity.medium'))
      },
      {
        value: PolicySeverityEnum.HIGH,
        label: t(key('severity.high'))
      }
    ]
  }

  const toggleControl = (name, value, disabled, className?) => {
    return (
      <FlexRow className={`alignCenter${className ? ' ' + className : ''}`}>
        <Checkbox
          id={testId(name)}
          label={t(key(`${name}.title`))}
          disabled={disabled}
          checked={value}
          onChange={({ target }) => onToggleChange(name, target.checked)}
        />
        <FlexRow className={'paddingLeft4 lineBreak'}>
          <HelpButton
            enabled
            description={t(
              key(`${name}.${disabled ? 'disabled_help' : 'help'}`)
            )}
          />
        </FlexRow>
      </FlexRow>
    )
  }

  const togglePreview = (name, value, className?) => {
    return (
      <PreviewItem
        className={className}
        label={key(`${name}.title`)}
        keys={[value ? 'common.on' : 'common.off']}
      />
    )
  }

  return props.onChange ? (
    <>
      {severity && (
        <LabelSelect
          label={t(key('severity.title'))}
          className={'paddingBottom16'}
          helpButton={
            <HelpButton enabled description={t(key('severity.help'))} />
          }
          placeholder={t('common.select-option')}
          options={severityOptions()}
          id={testId('severity')}
          value={[metadata.severity]}
          onChange={onSeverityChange}
        />
      )}
      {toggleControl(
        PolicySecurityToggleEnum.UNSUPPORTED,
        metadata.unsupported,
        false,
        'paddingBottom16'
      )}
      {advanced &&
        toggleControl(
          PolicySecurityToggleEnum.UNENTITLED,
          !!metadata.unentitled, // newly added - can be null
          false,
          'paddingBottom16'
        )}
      {toggleControl(
        PolicySecurityToggleEnum.REMEDIATION,
        metadata.remediation,
        remediationDisabled
      )}
    </>
  ) : (
    <>
      {severity && (
        <PreviewItem
          className={'paddingBottom16'}
          label={key('severity.title')}
          value={
            severityOptions().find((x) => x.value === metadata.severity).label
          }
        />
      )}
      {togglePreview(
        PolicySecurityToggleEnum.UNSUPPORTED,
        metadata.unsupported,
        'paddingBottom16'
      )}
      {advanced &&
        togglePreview(
          PolicySecurityToggleEnum.UNENTITLED,
          metadata.unentitled,
          'paddingBottom16'
        )}
      {togglePreview(
        PolicySecurityToggleEnum.REMEDIATION,
        metadata.remediation
      )}
    </>
  )
}

export default memo(SecurityAttributes)
