import React, {
  useContext,
  useMemo,
  useEffect,
  useState,
  memo,
  useRef
} from 'react'
import configContext from 'context/config/configContext'
import { Password, Button } from '@veneer/core'
import { FlexColumn, FlexRow, TextBlack } from 'styles/styles'
import { PasswordDescription } from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import settingsContext from 'context/settings/settingsContext'
import HelpButton from 'components/policies/settings/attributes/HelpButton'
import { RequirementInterface } from '@veneer/core/dist/scripts/password/requirements/types'
import { getError } from 'common/utilities'

const PasswordControl = (props) => {
  const description: PasswordDescription = props.description

  const {
    id,
    attributes,
    data: { deviceSettings }
  } = props
  const {
    required = true,
    attribute,
    label,
    hint,
    editHint,
    editLabel,
    help,
    errors,
    confirmationHint,
    confirmationLabel,
    generate
  } = description

  const { tt } = useContext(configContext)
  const {
    isEnabled,
    addDisabled,
    removeDisabled,
    addError,
    removeError,
    displayAllErrors
  } = useContext(settingsContext)

  const getLocalized = (key: string, params?): string =>
    tt(props.localizationPath, key, params)

  const enabled = isEnabled(attribute)
  const [value, setValue] = useState('')
  const [valueConfirm, setValueConfirm] = useState('')
  const initialValue = useRef(
    PoliciesHelper.getData(description, deviceSettings)
  ).current
  const [focusIn, setFocusIn] = useState(false)

  const [error, setError] = useState(false)
  const [displayError, setDisplayError] = useState(displayAllErrors)
  const [confirmError, setConfirmError] = useState(false)
  const [displayConfirmError, setDisplayConfirmError] =
    useState(displayAllErrors)

  const showError = displayError || displayAllErrors
  const showConfirmError = displayConfirmError || displayAllErrors

  const oldPwd = (val) => initialValue && !val

  // Requirements based on value and errors
  const requirements: RequirementInterface[] = useMemo(
    () =>
      errors
        ? errors.map((error, index) => ({
            id: index + 1,
            status: !getError([error], value)
              ? 'complete'
              : displayAllErrors && !oldPwd(value)
              ? 'error'
              : 'incomplete',
            label: getLocalized(error.message, error.params)
          }))
        : [],
    [value, errors, displayAllErrors]
  )

  useEffect(() => {
    const err =
      !oldPwd(value) &&
      enabled &&
      requirements.some((req) => req.status !== 'complete')
    const confirmErr = !!confirmationHint && enabled && valueConfirm !== value

    setError(showError && err)
    setConfirmError(showConfirmError && confirmErr)

    if (err || confirmErr) {
      const show = (showError && err) || (showConfirmError && confirmErr)
      addError(id, attribute, show)
    } else {
      removeError(id, attribute)
    }
  }, [enabled, value, showError, showConfirmError, valueConfirm, requirements])

  useEffect(() => {
    let val = PoliciesHelper.getData(description, deviceSettings)
    if (val === initialValue) {
      val = ''
    }
    setValue(val)
    if (!val) {
      // clear confirm if value is empty
      setValueConfirm(val)
    }
  }, [deviceSettings])

  useEffect(() => {
    // make sure that attribute value is up-to-date, otherwise just wait for sync
    const val = PoliciesHelper.getData(description, deviceSettings)
    if (attributes && val === value) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  const setData = (val) => {
    const settings = [...deviceSettings]
    PoliciesHelper.setData(
      description,
      settings,
      oldPwd(val) ? initialValue : val
    )
    props.onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const onChange = (val) => {
    setValue(val)
    setDisplayError(true)
    setData(val)
  }

  const onChangeConfirm = (val) => {
    setValueConfirm(val)
    setDisplayConfirmError(true)
  }

  const handleButtonClick = () => {
    const randomPassword = generate()
    setValue(randomPassword)
    setValueConfirm(randomPassword)
    setData(randomPassword)
  }

  return (
    <>
      {generate && (
        <FlexRow className={'paddingBottom16'}>
          <Button onClick={handleButtonClick} appearance={'secondary'}>
            {getLocalized('common.generate-password')}
          </Button>
        </FlexRow>
      )}

      {label && (
        <FlexRow className={'alignCenter marginBottom4'}>
          <TextBlack disabled={!enabled} required={required}>
            {getLocalized(initialValue && editLabel ? editLabel : label)}
          </TextBlack>
          {help && (
            <FlexRow className={'paddingLeft4 lineBreak'}>
              <HelpButton enabled={enabled} description={getLocalized(help)} />
            </FlexRow>
          )}
        </FlexRow>
      )}
      <Password
        id={attribute}
        placeholder={getLocalized(initialValue && editHint ? editHint : hint)}
        error={error}
        value={value}
        disabled={!enabled}
        onChange={onChange}
        className={'maxTextWidth'}
        requirements={focusIn ? requirements : null}
        onFocus={() => setFocusIn(true)}
        onBlur={() => {
          setFocusIn(false)
        }}
        helperText={
          error && !focusIn
            ? getLocalized('common.errors.password-requirements')
            : null
        }
      />

      {confirmationHint && (
        <FlexColumn className={'paddingTop16'}>
          {confirmationLabel && (
            <TextBlack
              disabled={!enabled}
              required={required}
              className={'marginBottom4'}
            >
              {getLocalized(confirmationLabel)}
            </TextBlack>
          )}

          <Password
            id={`${attribute}.confirm`}
            placeholder={getLocalized(confirmationHint)}
            helperText={
              confirmError
                ? getLocalized('common.errors.password-mismatch')
                : null
            }
            value={valueConfirm}
            error={confirmError}
            disabled={!enabled}
            onChange={onChangeConfirm}
            className={'maxTextWidth'}
          />
        </FlexColumn>
      )}
    </>
  )
}

export default memo(PasswordControl)
