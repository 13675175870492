import React from 'react'
import PoliciesConfiguration, {
  PolicyAttributeDescription,
  PolicyItemPrimitives
} from './PoliciesConfiguration'
import { Attribute } from 'common/model/api/Policy'
import PasswordControl from 'components/policies/settings/attributes/device/controls/PasswordControl'
import PasswordViewControl from 'components/policies/settings/attributes/device/controls/PasswordViewControl'
import TextBoxControl from 'components/policies/settings/attributes/device/controls/TextBoxControl'
import ToggleControl from 'components/policies/settings/attributes/device/controls/ToggleControl'
import ToggleViewControl from 'components/policies/settings/attributes/device/controls/ToggleViewControl'
import DropBoxControl from 'components/policies/settings/attributes/device/controls/DropBoxControl'
import PriorityListControl from 'components/policies/settings/attributes/device/controls/PriorityListControl'
import MultiSelectCheckbox from 'components/policies/settings/attributes/device/controls/MultiSelectCheckbox/MultiSelectCheckboxControl'
import DelayVeryLowMessageControl from 'components/policies/settings/attributes/device/controls/DelayVeryLowMessage/DelayVeryLowMessageControl'
import FwUpdateVersionControl from 'components/policies/settings/attributes/device/controls/FwUpdateVersion/FwUpdateVersionControl'
import FwUpdateScheduleControl from 'components/policies/settings/attributes/device/controls/FwUpdateScheduleControl'
import TrayAdministrationControl from 'components/policies/settings/attributes/device/controls/TrayAdministration/TrayAdministrationControl'
import LanguageAndKeyboardLayoutControl from 'components/policies/settings/attributes/device/controls/HomeScreenLanguage/LanguageAndKeyboardLayoutControl'
import OutgoingServersControl from 'components/policies/settings/attributes/device/controls/OutgoingServers/OutgoingServersControl'
import CopyStampsControl from 'components/policies/settings/attributes/device/controls/CopyStamps/CopyStampsControl'
import EmailMessageControl from 'components/policies/settings/attributes/device/controls/EmailMessage/EmailMessageControl'
import FaxReceiveControl from 'components/policies/settings/attributes/device/controls/FaxReceive/FaxReceiveControl'
import FaxSendControl from 'components/policies/settings/attributes/device/controls/FaxSend/FaxSendControl'
import SimpleTableControl from 'components/policies/settings/attributes/device/controls/SimpleTable/SimpleTableControl'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import HomeScreenLanguageItems from './dropboxItems/HomeScreenLanguageItems'
import RestrictColorControl from 'components/policies/settings/attributes/device/controls/RestrictColor/RestrictColorControl'
import FileNameControl from 'components/policies/settings/attributes/device/controls/FileName/FileNameControl'
import RadioTextBoxControl from 'components/policies/settings/attributes/device/controls/RadioTextBoxControl'
import ObjectDropboxControl from 'components/policies/settings/attributes/device/controls/ObjectDropboxControl'
import AppDeploymentControl from 'components/policies/settings/attributes/device/controls/AppDeployment/AppDeploymentControl'
import SioConfigControl from 'components/policies/settings/attributes/device/controls/SioConfig/SioConfigControl'
import FileUploadControl from 'components/policies/settings/attributes/device/controls/FileUploadControl'
import WallpaperUploadControl from 'components/policies/settings/attributes/device/controls/WallpaperUploadControl'
import HomeScreenAppsControl from 'components/policies/settings/attributes/device/controls/HomeScreenApps/HomeScreenAppsControl'
import HomeScreenCustomControl from 'components/policies/settings/attributes/device/controls/HomeScreenCustom/HomeScreenCustomControl'
import WeeklyScheduleControl from 'components/policies/settings/attributes/device/controls/WeeklySchedule/WeeklyScheduleControl'
import HolidayScheduleControl from 'components/policies/settings/attributes/device/controls/HolidaySchedule/HolidayScheduleControl'
import QuickSetsControl from 'components/policies/settings/attributes/device/controls/QuickSets/QuickSetsControl'
import DeviceAccessRolesControl from 'components/policies/settings/attributes/device/controls/DeviceAccess/DeviceAccessRolesControl'
import ErrorControl from 'components/policies/settings/attributes/device/controls/ErrorControl'
import ControlPanelAccessControl from 'components/policies/settings/attributes/device/controls/DeviceAccess/ControlPanelAccessControl'
import WebEncryptionControl from 'components/policies/settings/attributes/device/controls/WebEncryption/WebEncryptionControl'
import CertificateUploadControl from 'components/policies/settings/attributes/device/controls/CertificateUploadControl'

interface DeviceSettingForm {
  form: React.JSX.Element
  key: number
  className?: string
}

export default class PoliciesGenerator {
  static generateDeviceSettings(
    data: Attribute,
    attributeDescription: PolicyAttributeDescription,
    attributes: Attribute[],
    onAttributeChange,
    onSettingsChanges
  ): DeviceSettingForm[] {
    const { id, deviceSettings } = attributeDescription
    if (!deviceSettings || !id) {
      return []
    }

    const deviceSettingsForm: DeviceSettingForm[] = []
    const localizationPath =
      PoliciesConfiguration.getPolicyAttrLocPath(id) + '.device_settings'

    const className = (indent, nonFirst) => {
      const classes = []
      if (nonFirst) {
        classes.push('paddingTop16')
      }
      if (indent) {
        classes.push(`devSettingsIndent${indent}`)
      }
      return classes.join(' ')
    }

    const controlProps = (description) => ({
      id,
      localizationPath,
      description,
      data,
      attributes,
      onAttributeChange,
      onSettingsChanges
    })

    for (const [key, description] of deviceSettings.entries()) {
      let form: React.JSX.Element
      switch (description.type) {
        case PolicyItemPrimitives.Password:
          form = <PasswordControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.PasswordView:
          form = (
            <PasswordViewControl
              localizationPath={localizationPath}
              description={description}
              onAttributeChange={onAttributeChange}
              onSettingsChanges={onSettingsChanges}
            />
          )
          break

        case PolicyItemPrimitives.Textbox:
          form = <TextBoxControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.Toggle:
          form = <ToggleControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.ToggleView:
          form = <ToggleViewControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.Dropbox:
          form = <DropBoxControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.MultiSelectCheckbox:
          form = <MultiSelectCheckbox {...controlProps(description)} />
          break

        case PolicyItemPrimitives.DelayLowMsgThreshold:
          form = <DelayVeryLowMessageControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.FwUpdateVersion:
          form = <FwUpdateVersionControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.FwUpdateSchedule:
          form = <FwUpdateScheduleControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.TrayAdministration:
          form = <TrayAdministrationControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.HomeScreenLanguageAndKeyboard:
          form = (
            <LanguageAndKeyboardLayoutControl {...controlProps(description)} />
          )
          break

        case PolicyItemPrimitives.PriorityList:
          form = <PriorityListControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.OutgoingServers:
          form = <OutgoingServersControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.RestrictColor:
          form = <RestrictColorControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.CopyStamps:
          form = <CopyStampsControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.AccessCtrlPermissions:
          form = <ControlPanelAccessControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.EmailMessage:
          form = <EmailMessageControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.FaxReceive:
          form = <FaxReceiveControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.FaxSend:
          form = <FaxSendControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.SimpleTable:
          form = <SimpleTableControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.FileName:
          form = <FileNameControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.RadioTextBox:
          form = <RadioTextBoxControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.ObjectDropBox:
          form = <ObjectDropboxControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.AppDeployment:
          form = <AppDeploymentControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.SioConfig:
          form = <SioConfigControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.FileUpload:
          form = <FileUploadControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.WallpaperUpload:
          form = <WallpaperUploadControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.CertificateUpload:
          form = <CertificateUploadControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.HomeScreenApplications:
          form = <HomeScreenAppsControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.HomeScreenCustomization:
          form = <HomeScreenCustomControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.WeeklySchedule:
          form = <WeeklyScheduleControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.HolidaySchedule:
          form = <HolidayScheduleControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.QuickSets:
          form = <QuickSetsControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.AccessCtrlRoles:
          form = <DeviceAccessRolesControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.WebEncryption:
          form = <WebEncryptionControl {...controlProps(description)} />
          break

        case PolicyItemPrimitives.Error:
          form = (
            <ErrorControl
              id={id}
              localizationPath={localizationPath}
              description={description}
            />
          )
          break
      }
      deviceSettingsForm.push({
        form,
        key,
        className: className(description.indent, key > 0)
      })
    }
    return deviceSettingsForm
  }

  static generateDeviceSettingsPreview(
    data: Attribute,
    attributeDescription: PolicyAttributeDescription
  ): DeviceSettingForm[] {
    const { deviceSettings, id } = attributeDescription
    if (!deviceSettings || !id) {
      return []
    }

    const deviceSettingsForm: DeviceSettingForm[] = []
    const localizationPath =
      PoliciesConfiguration.getPolicyAttrLocPath(id) + '.device_settings'
    const getKey = (key) => {
      return !key
        ? undefined
        : key.includes('.')
        ? key
        : `${localizationPath}.${key}`
    }

    const controlProps = (description, compliance?) => ({
      id,
      localizationPath,
      description,
      data,
      compliance
    })

    for (const [index, description] of deviceSettings.entries()) {
      const key = index * 10
      let value = undefined
      let keys = undefined

      const className = !key ? '' : 'paddingTop16'

      // Optional compliance
      const setting = data.deviceSettings.find(
        (x) => x.name === description.attribute
      )
      let compliance = setting ? setting.compliance : undefined

      switch (description.type) {
        case PolicyItemPrimitives.Password:
          keys = [
            PoliciesHelper.getData(description, data.deviceSettings)
              ? 'common.configured'
              : 'common.not-configured'
          ]
          break

        case PolicyItemPrimitives.PasswordView:
          value = PoliciesHelper.getPassword()
          break

        case PolicyItemPrimitives.Textbox:
          value = PoliciesHelper.getData(description, data.deviceSettings)
          break

        case PolicyItemPrimitives.Toggle:
        case PolicyItemPrimitives.ToggleView:
          keys = [
            PoliciesHelper.getData(description, data.deviceSettings)
              ? 'common.on'
              : 'common.off'
          ]
          break

        case PolicyItemPrimitives.Dropbox: {
          value = PoliciesHelper.getData(description, data.deviceSettings)
          const item = description.items.find((item) => item.value === value)
          if (item?.label) {
            value = undefined
            keys = [getKey(item.label)]
          }
          break
        }

        case PolicyItemPrimitives.ObjectDropBox: {
          value = PoliciesHelper.getData(description, data.deviceSettings).value
          compliance = PoliciesHelper.getCompliance(
            description,
            data.deviceSettings
          ).value
          const item = description.items.find((item) => item.value === value)
          if (item) {
            value = undefined
            keys = [getKey(item.label)]
          }
          break
        }

        case PolicyItemPrimitives.DelayLowMsgThreshold: {
          deviceSettingsForm.push({
            form: <DelayVeryLowMessageControl {...controlProps(description)} />,
            key,
            className
          })
          continue
        }

        case PolicyItemPrimitives.TrayAdministration:
          deviceSettingsForm.push({
            form: <TrayAdministrationControl {...controlProps(description)} />,
            key,
            className
          })
          continue

        case PolicyItemPrimitives.HomeScreenLanguageAndKeyboard: {
          const valueArr = PoliciesHelper.getData(
            description,
            data.deviceSettings
          )
          value = getKey('kb-layout-preview-entry')
          keys = []
          valueArr.forEach((item: { language; layout }) => {
            const language = description.items.find(
              (x) => x.value === item.language
            )
            const layout = HomeScreenLanguageItems.keyboardLayouts.find(
              (x) => x.value === item.layout
            )
            if (language && layout) {
              keys.push([language.label, layout.label].map((x) => getKey(x)))
            }
          })
          break
        }

        case PolicyItemPrimitives.PriorityList: {
          const valueArr: string[] = PoliciesHelper.getData(
            description,
            data.deviceSettings
          )
          value = getKey('preview-entry')
          keys = []
          valueArr.forEach((item) => {
            const found = description.items.find((x) => x.value === item)
            if (found) {
              keys.push([getKey(found.label)])
            }
          })
          break
        }

        case PolicyItemPrimitives.MultiSelectCheckbox: {
          const valueArr: string[] = PoliciesHelper.getData(
            description,
            data.deviceSettings
          )
          keys = description.items
            .filter(({ value }) => valueArr.includes(value))
            .map(({ label }) => getKey(label))
          break
        }

        case PolicyItemPrimitives.OutgoingServers:
          deviceSettingsForm.push({
            form: (
              <OutgoingServersControl
                {...controlProps(description, compliance)}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.RestrictColor:
          deviceSettingsForm.push({
            form: (
              <RestrictColorControl
                {...controlProps(description, compliance)}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.CopyStamps:
          deviceSettingsForm.push({
            form: (
              <CopyStampsControl {...controlProps(description, compliance)} />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.AccessCtrlPermissions:
          deviceSettingsForm.push({
            form: (
              <ControlPanelAccessControl
                {...controlProps(description, compliance)}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.EmailMessage:
          deviceSettingsForm.push({
            form: <EmailMessageControl {...controlProps(description)} />,
            key,
            className
          })
          continue

        case PolicyItemPrimitives.FaxReceive:
          deviceSettingsForm.push({
            form: <FaxReceiveControl {...controlProps(description)} />,
            key,
            className
          })
          continue

        case PolicyItemPrimitives.FaxSend:
          deviceSettingsForm.push({
            form: <FaxSendControl {...controlProps(description)} />,
            key,
            className
          })
          continue

        case PolicyItemPrimitives.SimpleTable: {
          deviceSettingsForm.push({
            form: (
              <SimpleTableControl {...controlProps(description, compliance)} />
            ),
            key,
            className
          })
          continue
        }

        case PolicyItemPrimitives.FwUpdateVersion:
          deviceSettingsForm.push({
            form: (
              <FwUpdateVersionControl
                {...controlProps(description, compliance)}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.FwUpdateSchedule:
          deviceSettingsForm.push({
            form: <FwUpdateScheduleControl {...controlProps(description)} />,
            key,
            className
          })
          continue

        case PolicyItemPrimitives.FileName:
          deviceSettingsForm.push({
            form: <FileNameControl {...controlProps(description)} />,
            key,
            className
          })
          continue

        case PolicyItemPrimitives.RadioTextBox:
          deviceSettingsForm.push({
            form: <RadioTextBoxControl {...controlProps(description)} />,
            key,
            className
          })
          continue

        case PolicyItemPrimitives.AppDeployment:
          deviceSettingsForm.push({
            form: (
              <AppDeploymentControl
                {...controlProps(description, compliance)}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.SioConfig:
          deviceSettingsForm.push({
            form: <SioConfigControl {...controlProps(description)} />,
            key,
            className
          })
          continue

        case PolicyItemPrimitives.FileUpload:
          deviceSettingsForm.push({
            form: (
              <FileUploadControl {...controlProps(description, compliance)} />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.WallpaperUpload:
          deviceSettingsForm.push({
            form: (
              <WallpaperUploadControl
                {...controlProps(description, compliance)}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.CertificateUpload:
          deviceSettingsForm.push({
            form: (
              <CertificateUploadControl
                {...controlProps(description, compliance)}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.HomeScreenApplications:
          deviceSettingsForm.push({
            form: (
              <HomeScreenAppsControl
                {...controlProps(description, compliance)}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.HomeScreenCustomization:
          deviceSettingsForm.push({
            form: (
              <HomeScreenCustomControl
                {...controlProps(description, compliance)}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.WeeklySchedule:
          deviceSettingsForm.push({
            form: (
              <WeeklyScheduleControl
                {...controlProps(description, compliance)}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.HolidaySchedule:
          deviceSettingsForm.push({
            form: (
              <HolidayScheduleControl
                {...controlProps(description, compliance)}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.QuickSets:
          deviceSettingsForm.push({
            form: (
              <QuickSetsControl {...controlProps(description, compliance)} />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.AccessCtrlRoles:
          deviceSettingsForm.push({
            form: (
              <DeviceAccessRolesControl
                {...controlProps(description, compliance)}
              />
            ),
            key,
            className
          })
          continue

        case PolicyItemPrimitives.WebEncryption:
          deviceSettingsForm.push({
            form: <WebEncryptionControl {...controlProps(description)} />,
            key,
            className
          })
          continue
      }

      deviceSettingsForm.push({
        form: (
          <PreviewItem
            label={getKey(description['label'])}
            value={value}
            keys={keys}
            compliance={compliance}
            className={className}
          />
        ),
        key
      })
    }
    return deviceSettingsForm
  }
}
