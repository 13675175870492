import { Item } from '../PoliciesConfiguration'
import { PolicyAttributeEnum } from '../PoliciesCategories'

const encryptionKeyLengthItem = (value: string) => ({
  value
})

const connectionTypeItem = (x: string) => ({
  value: 'certificate-connection.' + x,
  label: 'policy.settings.certificate-connections.' + x
})

const certificateItem = (setting: string, x: string) => ({
  value: `${PolicyAttributeEnum.Identity_Certificate}.${setting}.${x}`,
  label: `${setting}-${x}`
})

const encryptionKeyItem = (x: string) => certificateItem('encryption-key', x)
const authorityItem = (x: string) => certificateItem('authority', x)
const sourceItem = (x: string) => certificateItem('source', x)
const algorithmItem = (x: string) => certificateItem('algorithm', x)

export default class CertificateItems {
  static encryptionKeyLengths: Item[] = [
    encryptionKeyLengthItem('1024'),
    encryptionKeyLengthItem('2048'),
    encryptionKeyLengthItem('3072'),
    encryptionKeyLengthItem('4096'),
    encryptionKeyLengthItem('8192')
  ]

  static encryptionKeys: Item[] = [
    encryptionKeyItem('rsa'),
    encryptionKeyItem('ecdsa')
  ]

  static connectionTypes: Item[] = [
    connectionTypeItem('wired'),
    connectionTypeItem('wifi')
  ]

  static certificateAuthorities: Item[] = [
    authorityItem('ms-standalone'),
    authorityItem('est-connector'),
    authorityItem('ms-enterprise'),
    authorityItem('opentrust'),
    authorityItem('scep-connector'),
    authorityItem('digicert')
  ]

  static certificateSources: Item[] = [
    sourceItem('best'),
    sourceItem('hpsm'),
    sourceItem('device')
  ]

  static certificateAlgorithms: Item[] = [
    algorithmItem('sha1'),
    algorithmItem('sha256'),
    algorithmItem('sha384'),
    algorithmItem('sha512')
  ]
}
