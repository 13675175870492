import React, { useContext, useEffect, useState } from 'react'
import { Button, ButtonGroup, Checkbox, Modal } from '@veneer/core'
import configContext from 'context/config/configContext'
import 'components/policies/modal/index.scss'
import 'styles/global.scss'
import { FlexRow, FlexColumn, TextBlack } from 'styles/styles'
import EmailAddressItems from 'context/policies/dropboxItems/EmailAddressItems'
import LabelTextBox from 'common/controls/labelTextBox'
import LabelSelect from 'common/controls/labelSelect'
import { getError, isTrueString } from 'common/utilities'
import PoliciesErrors from 'context/policies/PoliciesErrors'
import { EmailMessageEnum } from './EmailMessageEnums'

export const requiredIds = [
  EmailMessageEnum.FROM,
  EmailMessageEnum.TO,
  EmailMessageEnum.CC,
  EmailMessageEnum.BCC,
  // Optional
  EmailMessageEnum.DEF_FROM,
  EmailMessageEnum.DEF_NAME,
  // No need to check in control
  EmailMessageEnum.SUBJECT,
  EmailMessageEnum.MESSAGE
]

const errorNotSelected = 'common.errors.not-selected'

const EmailAddressMessageEditModal = (props) => {
  const { tt } = useContext(configContext)

  const getLocalized = (key: string, params?): string =>
    tt(props.localizationPath, key, params)

  const { constraints, onClose, checkConstraints } = props
  const [value, setValue] = useState(props.value)
  const [requiredErrors, setRequiredErrors] = useState({})
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)

  useEffect(() => {
    setIsSaveDisabled(JSON.stringify(value) === JSON.stringify(props.value))
  }, [value, props.value])

  const checkSelectErrors = () => {
    const errors = { ...requiredErrors }
    requiredIds
      .filter((id) => checkConstraints(id))
      .forEach((id) => {
        const getErrorObject = (x) => {
          switch (id) {
            case EmailMessageEnum.DEF_FROM:
              return getError(PoliciesErrors.errorsInvalidEmail, x)
            case EmailMessageEnum.DEF_NAME:
              return getError(PoliciesErrors.errorsDefFromName(constraints), x)
            case EmailMessageEnum.SUBJECT:
              return getError(PoliciesErrors.errorsSubject(constraints), x)
            case EmailMessageEnum.MESSAGE:
              return getError(PoliciesErrors.errorsMessage(constraints), x)
            default:
              return x ? null : { message: errorNotSelected }
          }
        }
        const newError = getErrorObject(value[id])
        if (newError) {
          errors[id] = newError
        } else {
          delete errors[id]
        }
      })

    // DEF_FROM & DEF_NAME are not always required
    if (value[EmailMessageEnum.FROM] !== EmailAddressItems.fromItems[0].value) {
      delete errors[EmailMessageEnum.DEF_FROM]
      delete errors[EmailMessageEnum.DEF_NAME]
    }
    setRequiredErrors(errors)
    return errors
  }

  const getFromItems = () =>
    EmailAddressItems.fromItems.map((item) => ({
      ...item,
      label: getLocalized(item.label)
    }))

  const getToCcBccItems = () =>
    EmailAddressItems.toItems.map((item) => ({
      ...item,
      label: getLocalized(item.label)
    }))

  const selectBoxesData = {
    from: {
      value: value[EmailMessageEnum.FROM],
      options: getFromItems()
    },
    to: {
      value: value[EmailMessageEnum.TO],
      options: getToCcBccItems()
    },
    cc: {
      value: value[EmailMessageEnum.CC],
      options: getToCcBccItems()
    },
    bcc: {
      value: value[EmailMessageEnum.BCC],
      options: getToCcBccItems()
    }
  }

  const checkboxHandle = (e) => {
    const { checked, id } = e.target
    setValue({ ...value, [id]: checked + '' })
  }

  const userEditableCheckBox = (id) => (
    <FlexRow className={'paddingTop16 devSettingsIndent1'}>
      <Checkbox
        id={id}
        label={getLocalized('user-editable')}
        checked={isTrueString(value[id])}
        key={id}
        onChange={checkboxHandle}
      />
    </FlexRow>
  )

  const onSaveClick = () => {
    const selectErrors = checkSelectErrors()
    if (!Object.keys(selectErrors).length) {
      props.onChange(value)
      onClose()
    }
  }

  const textBoxDisabled = value.from !== EmailAddressItems.fromItems[0].value

  const selectBox = (id: string) => {
    const selectBoxData = selectBoxesData[id]
    const error = requiredErrors[id]
    return (
      <LabelSelect
        label={getLocalized(id)}
        className={'maxTextWidth'}
        id={id}
        options={selectBoxData.options}
        placeholder={getLocalized('common.select-option')}
        value={[selectBoxData.value]}
        error={!!error}
        helperText={
          error?.message ? getLocalized(error?.message, error?.params) : null
        }
        onChange={(option) => {
          setRequiredErrors((previousValue) => {
            const newValue = { ...previousValue }
            if (!option.value) {
              newValue[id] = { message: errorNotSelected }
            } else {
              delete newValue[id]
            }
            return newValue
          })
          setValue({ ...value, [id]: option.value })
        }}
      />
    )
  }

  const selectWithLabelAndCheckBox = (id: string, hasCheckBox = true) => (
    <FlexColumn className={'paddingTop16'}>
      {selectBox(id)}
      {hasCheckBox && userEditableCheckBox(id + EmailMessageEnum.EDITABLE)}
    </FlexColumn>
  )

  const textBoxWithLabelAndCheckBox = (
    id: EmailMessageEnum,
    policyError,
    textArea: boolean,
    hasCheckBox: boolean,
    disabled?: boolean,
    required?: boolean
  ) => {
    const hint = `${id}_hint`
    const error = requiredErrors[id]
    return (
      <FlexColumn className={'paddingTop16'}>
        <LabelTextBox
          id={id}
          required={required}
          label={getLocalized(id)}
          disabled={disabled}
          value={value[id] || ''}
          placeholder={getLocalized(hint)}
          error={!!error}
          helperText={getLocalized(error?.message || hint, error?.params)}
          className={'maxTextWidth'}
          textArea={textArea}
          onChange={(e) => {
            setRequiredErrors((previousValue) => {
              const newValue = { ...previousValue }
              const newError = getError(policyError, e)
              if (newError) {
                newValue[id] = newError
              } else {
                delete newValue[id]
              }
              return newValue
            })
            setValue({ ...value, [id]: e })
          }}
        />
        {hasCheckBox && userEditableCheckBox(id + EmailMessageEnum.EDITABLE)}
      </FlexColumn>
    )
  }

  return (
    <Modal
      onClose={onClose}
      closeOnBlur={false}
      show={true}
      title={getLocalized('title')}
      className={'small-policy-modal'}
      data-testid={'id-set-email-address-modal'}
      footer={
        <ButtonGroup>
          <Button disabled={isSaveDisabled} onClick={onSaveClick}>
            {getLocalized('common.save')}
          </Button>
          <Button appearance={'secondary'} onClick={onClose}>
            {getLocalized('common.cancel')}
          </Button>
        </ButtonGroup>
      }
    >
      <TextBlack>{getLocalized('description-message')}</TextBlack>

      {selectWithLabelAndCheckBox(
        EmailMessageEnum.FROM,
        checkConstraints(EmailMessageEnum.FROM + EmailMessageEnum.EDITABLE)
      )}

      <FlexColumn className={'devSettingsIndent1'}>
        {textBoxWithLabelAndCheckBox(
          EmailMessageEnum.DEF_FROM,
          PoliciesErrors.errorsInvalidEmail,
          false,
          false,
          textBoxDisabled,
          true
        )}

        {textBoxWithLabelAndCheckBox(
          EmailMessageEnum.DEF_NAME,
          PoliciesErrors.errorsDefFromName(constraints),
          false,
          false,
          textBoxDisabled,
          true
        )}
      </FlexColumn>

      {selectWithLabelAndCheckBox(EmailMessageEnum.TO)}

      {checkConstraints(EmailMessageEnum.CC) &&
        selectWithLabelAndCheckBox(
          EmailMessageEnum.CC,
          checkConstraints(EmailMessageEnum.CC + EmailMessageEnum.EDITABLE)
        )}

      {checkConstraints(EmailMessageEnum.BCC) &&
        selectWithLabelAndCheckBox(
          EmailMessageEnum.BCC,
          checkConstraints(EmailMessageEnum.BCC + EmailMessageEnum.EDITABLE)
        )}

      {textBoxWithLabelAndCheckBox(
        EmailMessageEnum.SUBJECT,
        PoliciesErrors.errorsSubject(constraints),
        false,
        checkConstraints(EmailMessageEnum.SUBJECT + EmailMessageEnum.EDITABLE)
      )}

      {textBoxWithLabelAndCheckBox(
        EmailMessageEnum.MESSAGE,
        PoliciesErrors.errorsMessage(constraints),
        true,
        checkConstraints(EmailMessageEnum.MESSAGE + EmailMessageEnum.EDITABLE)
      )}
    </Modal>
  )
}
export default EmailAddressMessageEditModal
