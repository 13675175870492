import React, { memo } from 'react'
import { TextBox, TextArea } from '@veneer/core'
import { FlexRow, TextBlack } from 'styles/styles'

const LabelTextBox = (props) => {
  const { label, required, helpButton, textArea, ...params } = props
  return (
    <>
      {label && (
        <FlexRow className={'alignCenter marginBottom4'}>
          <TextBlack disabled={params.disabled} required={required}>
            {label}
          </TextBlack>
          <FlexRow className={'paddingLeft4 lineBreak'}>{helpButton}</FlexRow>
        </FlexRow>
      )}
      {textArea ? <TextArea {...params} /> : <TextBox {...params} />}
    </>
  )
}

export default memo(LabelTextBox)
