import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Modal } from '@veneer/core'
import { TextBlack } from 'styles/styles'
import ImageSettingItems from 'context/policies/dropboxItems/ImageSettingItems'
import PrintEmailNotificationItems from 'context/policies/dropboxItems/PrintEmailNotificationItems'
import { getError } from 'common/utilities'
import PoliciesErrors from 'context/policies/PoliciesErrors'
import LabelTextBox from 'common/controls/labelTextBox'
import LabelSelect from 'common/controls/labelSelect'
export enum faxSendCommonEnum {
  BG_CLEANUP = 'common-bg-cleanup',
  DARKNESS = 'common-darkness',
  CONTRAST = 'common-contrast',
  SHARPNESS = 'common-sharpness',
  NOTIFICATION_CONDITION = 'common-notification-condition',
  NOTIFICATION_METHOD = 'common-notification-method',
  NOTIFICATION_ADDRESS = 'common-notification-address'
}

const dropdownItems = [
  {
    key: faxSendCommonEnum.BG_CLEANUP,
    options: ImageSettingItems.imageBgCleanupItems
  },
  {
    key: faxSendCommonEnum.DARKNESS,
    options: ImageSettingItems.imageDarknessItems
  },
  {
    key: faxSendCommonEnum.CONTRAST,
    options: ImageSettingItems.imageContrastItems
  },
  {
    key: faxSendCommonEnum.SHARPNESS,
    options: ImageSettingItems.imageSharpnessItems
  },
  {
    key: faxSendCommonEnum.NOTIFICATION_CONDITION,
    options: PrintEmailNotificationItems.emailNotificationConditions
  },
  {
    key: faxSendCommonEnum.NOTIFICATION_METHOD,
    options: PrintEmailNotificationItems.emailNotificationMethods,
    className: 'devSettingsIndent1'
  }
]

const FaxSendCommonModal = (props) => {
  const { onChange, onClose, getLocalized } = props
  const [value, setValue] = useState(props.value)
  const [textError, setTextError] = useState(null)
  const [selectError, setSelectError] = useState({})
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)

  const onSave = () => {
    if (!retrievedErrors()) {
      onChange(value)
      onClose()
    }
  }

  useEffect(() => {
    setSaveButtonDisabled(JSON.stringify(props.value) === JSON.stringify(value))
  }, [props.value, value])

  const isMethod = PrintEmailNotificationItems.emailNotificationConditions
    .slice(1)
    .map(({ value }) => value)
    .includes(value[faxSendCommonEnum.NOTIFICATION_CONDITION])

  const areElementsDisabled = !(
    isMethod &&
    value[faxSendCommonEnum.NOTIFICATION_METHOD] ===
      PrintEmailNotificationItems.emailNotificationMethods[1].value
  )

  const retrievedErrors = () => {
    const selectErrors = dropdownItems.map((item) => {
      const errorValue = item.options.some((element) => {
        if (isDisabled(item.key)) {
          return true
        }
        return element.value === value[item.key]
      })
      setSelectError((prevValue) => {
        return { ...prevValue, [item.key]: !errorValue }
      })
      return errorValue
    })

    const getErrors = getError(
      PoliciesErrors.errorsInvalidEmail,
      value[faxSendCommonEnum.NOTIFICATION_ADDRESS]
    )
    setTextError(getErrors)
    return (
      (!areElementsDisabled && getErrors) || selectErrors.some((item) => !item)
    )
  }

  const handleChange = (key, data) => {
    setValue((prevValue) => {
      return { ...prevValue, [key]: data }
    })
  }

  const isDisabled = (key) => {
    return key === faxSendCommonEnum.NOTIFICATION_METHOD && !isMethod
  }

  const selectComponent = (key, options, className?) => {
    return (
      <div key={key} className={className}>
        <LabelSelect
          label={getLocalized(key)}
          options={options.map((item) => {
            return {
              value: item.value,
              label: getLocalized(item.label)
            }
          })}
          onChange={(data) => {
            setSelectError((prevValue) => {
              return { ...prevValue, [key]: false }
            })
            handleChange(key, data.value)
          }}
          placeholder={getLocalized('common.select-option')}
          id={key}
          value={[value[key]]}
          disabled={isDisabled(key)}
          error={!!selectError[key]}
          helperText={
            selectError[key] && getLocalized('common.errors.not-selected')
          }
          className={'paddingBottom16 maxTextWidth'}
        />
      </div>
    )
  }

  return (
    <Modal
      show={true}
      onClose={onClose}
      closeOnBlur={false}
      className={'small-policy-modal'}
      title={getLocalized('common-settings')}
      footer={
        <ButtonGroup>
          <Button onClick={onSave} disabled={saveButtonDisabled}>
            {getLocalized('common.save')}
          </Button>
          <Button appearance={'secondary'} onClick={onClose}>
            {getLocalized('common.cancel')}
          </Button>
        </ButtonGroup>
      }
      data-testid={'id-fax-send-common-modal'}
    >
      <TextBlack>{getLocalized('common-description-message')}</TextBlack>

      <TextBlack className={'paddingTop16 paddingBottom16 bold'}>
        {getLocalized('common-scan-settings')}
      </TextBlack>

      {dropdownItems.slice(0, 4).map((item) => {
        return selectComponent(item.key, item.options)
      })}

      <TextBlack className={'paddingTop16 paddingBottom16 bold'}>
        {getLocalized('common-notification-settings')}
      </TextBlack>

      {dropdownItems.slice(4).map((item) => {
        return selectComponent(item.key, item.options, item.className)
      })}

      <div className={'devSettingsIndent2'}>
        <LabelTextBox
          label={getLocalized(faxSendCommonEnum.NOTIFICATION_ADDRESS)}
          required={true}
          value={value[faxSendCommonEnum.NOTIFICATION_ADDRESS] || ''}
          placeholder={getLocalized(
            faxSendCommonEnum.NOTIFICATION_ADDRESS + '_hint'
          )}
          onChange={(value) => {
            setTextError(getError(PoliciesErrors.errorsInvalidEmail, value))
            handleChange(faxSendCommonEnum.NOTIFICATION_ADDRESS, value)
          }}
          id={faxSendCommonEnum.NOTIFICATION_ADDRESS}
          error={!!textError}
          helperText={getLocalized(
            textError?.message ||
              faxSendCommonEnum.NOTIFICATION_ADDRESS + '_hint',
            textError?.params
          )}
          disabled={areElementsDisabled}
          className={'maxTextWidth'}
        ></LabelTextBox>
      </div>
    </Modal>
  )
}

export default FaxSendCommonModal
