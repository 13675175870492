import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Modal } from '@veneer/core'
import 'components/policies/modal/index.scss'
import 'styles/global.scss'
import { TextBlack } from 'styles/styles'
import MediaTrayItems from 'context/policies/dropboxItems/MediaTrayItems'
import PrintEmailNotificationItems from 'context/policies/dropboxItems/PrintEmailNotificationItems'
import LabelTextBox from 'common/controls/labelTextBox'
import LabelSelect from 'common/controls/labelSelect'
import { getError } from 'common/utilities'
import PoliciesErrors from 'context/policies/PoliciesErrors'
import { Item } from 'context/policies/PoliciesConfiguration'

export enum faxReceiveCommonEnum {
  NOTIFICATION_ADDRESS = 'common-notification-address',
  NOTIFICATION_CONDITION = 'common-notification-condition',
  NOTIFICATION_METHOD = 'common-notification-method',
  TRAY = 'common-tray'
}

export const requiredIds = [
  faxReceiveCommonEnum.NOTIFICATION_CONDITION,
  faxReceiveCommonEnum.NOTIFICATION_METHOD,
  faxReceiveCommonEnum.TRAY
]

const getEmailError = (email) =>
  getError(PoliciesErrors.errorsInvalidEmail, email)

const selectError = { message: 'common.errors.not-selected' }

const FaxReceiveCommonModal = (props) => {
  const [value, setValue] = useState(props.value)
  const [emailError, setEmailError] =
    useState<{ message: string; params }>(null)
  const [selectErrors, setSelectErrors] = useState({})
  const [disableSave, setDisableSave] = useState(true)

  const getSelectOptions = (items) =>
    items.map(({ value, label }) => ({
      value,
      label: props.getLocalized(label)
    }))

  useEffect(() => {
    setDisableSave(JSON.stringify(props.value) === JSON.stringify(value))
  }, [props.value, value])

  const checkSelectErrors = () => {
    const errors = { ...selectErrors }
    requiredIds.forEach((id) => {
      if (!value[id]) {
        errors[id] = selectError
      } else {
        delete errors[id]
      }
    })
    setSelectErrors(errors)
    return errors
  }

  const labelAndSelect = (
    id: faxReceiveCommonEnum,
    options: Item[],
    disabled?: boolean
  ) => {
    const error = selectErrors[id]
    return (
      <>
        <LabelSelect
          label={props.getLocalized(id)}
          className={'paddingBottom16 maxTextWidth'}
          id={id}
          options={options}
          placeholder={props.getLocalized('common.select-option')}
          error={!!error}
          helperText={
            error?.message
              ? props.getLocalized(error?.message, error?.params)
              : null
          }
          value={[value[id]]}
          onChange={(option) => {
            setSelectErrors((prevErrors) => {
              const newErrors = { ...prevErrors }
              if (!option.value) {
                newErrors[id] = selectError
              } else {
                delete newErrors[id]
              }
              return newErrors
            })
            setValue({ ...value, [id]: option.value })
          }}
          disabled={disabled}
        />
      </>
    )
  }

  const isMethod = PrintEmailNotificationItems.emailNotificationConditions
    .slice(1)
    .map(({ value }) => value)
    .includes(value[faxReceiveCommonEnum.NOTIFICATION_CONDITION])

  const shouldDisableAddress = !(
    isMethod &&
    value[faxReceiveCommonEnum.NOTIFICATION_METHOD] ===
      PrintEmailNotificationItems.emailNotificationMethods[1].value
  )

  const onSave = () => {
    const emailError = getEmailError(
      value[faxReceiveCommonEnum.NOTIFICATION_ADDRESS]
    )
    setEmailError(emailError)
    const selectErrors = checkSelectErrors()
    if (Object.keys(selectErrors).length) return

    if (!emailError?.message || shouldDisableAddress) {
      props.onChange(value)
      props.onClose()
    }
  }

  return (
    <Modal
      onClose={props.onClose}
      closeOnBlur={false}
      show={true}
      footer={
        <ButtonGroup>
          <Button disabled={disableSave} onClick={onSave}>
            {props.getLocalized('common.save')}
          </Button>
          <Button appearance={'secondary'} onClick={props.onClose}>
            {props.getLocalized('common.cancel')}
          </Button>
        </ButtonGroup>
      }
      title={props.getLocalized('common-settings')}
      className={'small-policy-modal'}
      data-testid={'id-fax-receive-common-modal'}
    >
      <TextBlack className="paddingBottom16">
        {props.getLocalized('common-description-message')}
      </TextBlack>
      <TextBlack className="paddingBottom16 bold">
        {props.getLocalized('common-default-settings')}
      </TextBlack>

      {labelAndSelect(
        faxReceiveCommonEnum.TRAY,
        getSelectOptions(MediaTrayItems.faxReceiveTrays)
      )}

      <TextBlack className="paddingBottom16 bold">
        {props.getLocalized('common-notification-settings')}
      </TextBlack>

      {labelAndSelect(
        faxReceiveCommonEnum.NOTIFICATION_CONDITION,
        getSelectOptions(
          PrintEmailNotificationItems.emailNotificationConditions
        )
      )}
      <div className="devSettingsIndent1">
        {labelAndSelect(
          faxReceiveCommonEnum.NOTIFICATION_METHOD,
          getSelectOptions(
            PrintEmailNotificationItems.emailNotificationMethods
          ),
          !isMethod
        )}
        <div className="devSettingsIndent1">
          <LabelTextBox
            label={props.getLocalized(
              faxReceiveCommonEnum.NOTIFICATION_ADDRESS
            )}
            required={true}
            id={faxReceiveCommonEnum.NOTIFICATION_ADDRESS}
            disabled={shouldDisableAddress}
            value={value[faxReceiveCommonEnum.NOTIFICATION_ADDRESS] || ''}
            onChange={(e) => {
              const emailError = getEmailError(e)
              setEmailError(emailError)
              setValue({
                ...value,
                [faxReceiveCommonEnum.NOTIFICATION_ADDRESS]: e
              })
            }}
            error={!!emailError?.message}
            helperText={props.getLocalized(
              emailError?.message ||
                `${faxReceiveCommonEnum.NOTIFICATION_ADDRESS}_hint`,
              emailError?.params
            )}
            className={'maxTextWidth'}
          />
        </div>
      </div>
    </Modal>
  )
}
export default FaxReceiveCommonModal
