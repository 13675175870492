import React, { useContext, useEffect } from 'react'
import settingsContext from 'context/settings/settingsContext'
import PolicyHeaderRight from './index'
import 'styles/global.scss'

const PolicyHeaderRightWrapper = (props) => {
  const { description, onRemove, nonEntitled } = props
  const { addWarning, getWarnings, getErrors } = useContext(settingsContext)

  // Could not find better place to set 'non-entitled' warning :(
  useEffect(() => {
    if (nonEntitled) {
      addWarning(description.id, 'non-entitled')
    }
  }, [nonEntitled])

  return (
    <PolicyHeaderRight
      description={description}
      errors={getErrors(description)}
      warnings={getWarnings(description)}
      onRemove={onRemove}
    />
  )
}

export default PolicyHeaderRightWrapper
