import React, { useState, useEffect } from 'react'
import { Button, ButtonGroup, Modal } from '@veneer/core'
import 'components/policies/modal/index.scss'
import 'styles/global.scss'
import { TextBlack } from 'styles/styles'
import LabelTextBox from 'common/controls/labelTextBox'
import { getError } from 'common/utilities'

const SimpleTableModal = (props) => {
  const {
    tableData,
    onClose,
    onChange,
    getLocalized,
    errors,
    modalDescription,
    modalTitle,
    limit,
    modalTextLabel,
    modalHelperText,
    duplicateError
  } = props
  const [selectButtonDisabled, setSelectButtonDisabled] = useState(true)
  const [textError, setTextError] = useState(null)
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    setSelectButtonDisabled(JSON.stringify(inputValue) === JSON.stringify(''))
  }, [inputValue])

  const handleTextChange = (value) => {
    retrievedErrors(value)
    setInputValue(value)
  }

  const retrievedErrors = (value): boolean => {
    const retrieveError = getError(errors, value)
    if (duplicateError && tableData.some((item) => item.column === value)) {
      setTextError({ message: duplicateError })
    } else if (retrieveError) {
      setTextError(retrieveError)
    } else {
      setTextError('')
      return false
    }
    return true
  }

  const handleSelect = () => {
    if (!retrievedErrors(inputValue)) {
      onChange(inputValue)
      onClose()
    }
  }

  return (
    <Modal
      onClose={onClose}
      closeOnBlur={false}
      show={true}
      title={getLocalized(modalTitle ?? 'common.add')}
      className={'small-policy-modal'}
      data-testid={'id-simple-table-modal'}
      footer={
        <ButtonGroup>
          <Button onClick={handleSelect} disabled={selectButtonDisabled}>
            {getLocalized('common.add')}
          </Button>
          <Button appearance={'secondary'} onClick={onClose}>
            {getLocalized('common.cancel')}
          </Button>
        </ButtonGroup>
      }
    >
      {modalDescription && (
        <TextBlack className={'paddingBottom16'}>
          {getLocalized(modalDescription, { max: limit })}
        </TextBlack>
      )}

      <LabelTextBox
        label={getLocalized(modalTextLabel)}
        required={true}
        id={'address'}
        type={'text'}
        placeholder={getLocalized(modalHelperText)}
        value={inputValue || ''}
        onChange={(value) => {
          handleTextChange(value)
        }}
        error={!!textError}
        helperText={getLocalized(
          textError?.message || modalHelperText,
          textError?.params
        )}
        className={'maxTextWidth'}
      />
    </Modal>
  )
}
export default SimpleTableModal
