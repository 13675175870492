import React, { useReducer } from 'react'
import axios from 'axios'
import {
  GrantControllerSvcClient,
  ContentBrokerSvcClient,
  Stack
} from '@jarvis/web-stratus-client'
import { SolutionActions } from 'context/types'
import SolutionsContext from 'context/solutions/solutionsContext'
import SolutionsReducer from 'context/solutions/SolutionsReducer'

const solutonsParams = {
  grant: 'ws-hp.com/workpath-app',
  level: 'ACCOUNT',
  includeSources: true
}

const criterionUuidStates = (uuids) => [
  {
    operation: 'and',
    criterion: [
      {
        operation: 'in',
        propertyName: 'uuid',
        propertyValue: uuids,
        propertyType: 'String',
        criterion: null
      },
      {
        operation: 'and',
        criterion: [
          {
            operation: 'in',
            propertyName: 'state',
            propertyValue: [
              'Published',
              'Private',
              'Unpublished',
              'TestFlight'
            ],
            propertyType: 'String',
            criterion: null
          },
          {
            operation: 'eq',
            propertyName: 'platformType',
            propertyValue: 'LinkForDevice',
            propertyType: 'String',
            criterion: null
          }
        ]
      }
    ]
  }
]

enum solutionErrorEnum {
  LIST = 'error.code.apps',
  DETAILS = 'error.code.app-details',
  LINK = 'error.code.app-link',
  MOC = 'error.code.moc'
}

const SolutionsProvider = (props) => {
  const initialState = {
    solutions: null, // array of solutions
    moc: null // MOC content
  }

  const [state, dispatch] = useReducer(SolutionsReducer, initialState)
  const jarvisAuthProvider = props.authProvider ? props.authProvider : null
  const { stack = Stack.pie, demoEnabled, authProvider } = props
  const grantSvcClient = new GrantControllerSvcClient(stack, authProvider)
  const contentBrokerSvcClient = new ContentBrokerSvcClient(
    props.contentBrokerStack ?? stack, // contentBrokerStack is only used for local testing
    jarvisAuthProvider
  )

  const hasEntitlement = async (
    grant: string,
    setResult: (result: boolean) => never
  ) => {
    demoEnabled
      ? setResult(true)
      : grantSvcClient
          .getGrants({ grant })
          .then((grants) =>
            setResult(
              grants?.status == 200 && grants.data?.contents?.length > 0
            )
          )
          .catch(() => setResult(false))
  }

  const getSolutions = (displayError) => {
    setSolutions(null)
    grantSvcClient
      .getGrants(solutonsParams)
      .then((res) => {
        if (res?.status === 200 && res.data?.contents?.length) {
          // get extended solution info
          const uuids = []
          res.data?.contents.forEach((x) =>
            x.sources?.forEach((y) => {
              if (y.source?.value?.appId) {
                uuids.push(y.source?.value?.appId)
              }
            })
          )
          getSolutionsEx(uuids, displayError)
        } else {
          setSolutions([])
        }
      })
      .catch(() => {
        setSolutions([])
        displayError({ text: solutionErrorEnum.LIST })
      })
  }

  const findResource = (metadata, kind) => {
    const meta: Record<string, unknown>[] = metadata
      ? Object.values(metadata)
      : []
    const found = meta.find((i) => i.dataKind === kind)
    return found
      ? { dataId: found.dataId, downloadLink: found.downloadLink }
      : found
  }

  const getAppDetails = async (criterionList, mapper) => {
    const params = (startIndex) => {
      return {
        method: 'POST',
        header: { 'X-HTTP-Method-Override': 'GET' },
        url: 'managedappinfos/managedapp',
        body: JSON.stringify({ startIndex, criterionList })
      }
    }
    const getAppMap = (resourceList, mapper, initialMap?) => {
      // pick app info from response, make a map of uuids
      return resourceList.reduce((acc, x) => {
        const app = { ...mapper(x), versionCode: x.versionCode }
        if (!acc[x.uuid]) {
          acc[x.uuid] = []
        }
        acc[x.uuid].push(app)
        return acc
      }, initialMap || {})
    }

    return new Promise((resolve, reject) => {
      contentBrokerSvcClient
        .relayContentMgt(params(0))
        .then((res) => {
          if (res?.status === 200 && res.data?.resourceList) {
            // get apps from first page
            let appMap = getAppMap(res.data.resourceList, mapper)

            // get the rest of apps from other pages
            const relayPromises = []
            const pageSize = res.data.resourceList.length
            for (let i = pageSize; i < res.data.totalCount; i += pageSize) {
              relayPromises.push(
                contentBrokerSvcClient.relayContentMgt(params(i))
              )
            }

            // combine results
            Promise.all(relayPromises)
              .then((results) => {
                results.forEach((res) => {
                  if (res?.status === 200 && res.data?.resourceList) {
                    appMap = getAppMap(res.data.resourceList, mapper, appMap)
                  }
                })

                resolve(appMap)
              })
              .catch(() => reject())
          } else {
            reject()
          }
        })
        .catch(() => reject())
    })
  }

  const sortAppMap = (appMap) => {
    // sort app versions
    Object.keys(appMap).forEach((uuid) =>
      appMap[uuid].sort((a, b) => b.versionCode - a.versionCode)
    )
  }

  const getSolutionsEx = (uuids, displayError) => {
    const mapper = (x) => {
      const solution = {
        name: x.name,
        provider: x.vendorName,
        version: x.version,
        appId: x.resourceId,
        mocId: findResource(x.metadata, 'MOC')?.dataId
      }
      const { downloadLink, dataId } = findResource(x.metadata, 'Icon39x39')
      return downloadLink
        ? { ...solution, icon: downloadLink }
        : { ...solution, iconId: dataId }
    }
    getAppDetails(criterionUuidStates(uuids), mapper)
      .then((appMap) => {
        // sort apps
        sortAppMap(appMap)
        const keys = Object.keys(appMap)

        // set solutions
        setSolutions(
          keys.map((uuid) => {
            const versions = appMap[uuid]
            const { name, appId, provider, icon, mocId } = versions[0]
            return {
              uuid,
              appId,
              mocId,
              icon,
              name,
              provider,
              versions
            }
          })
        )

        // get icons
        const linkPromises = []
        keys.forEach((uuid) => {
          const { appId, iconId } = appMap[uuid][0]
          if (iconId) {
            linkPromises.push(
              new Promise((resolve) =>
                getLink(appId, iconId, (icon) => resolve({ uuid, icon }))
              )
            )
          }
        })
        if (linkPromises.length > 0) {
          Promise.all(linkPromises).then((icons) => {
            const iconMap = icons.reduce((acc, x) => {
              acc[x.uuid] = x.icon
              return acc
            }, {})
            setIcons(iconMap)
          })
        }
      })
      .catch(() => {
        displayError({ text: solutionErrorEnum.DETAILS })
      })
  }

  const getMoc = (appIdMoc, displayError) => {
    setMoc(null)
    getLink(appIdMoc.appId, appIdMoc.mocId, (link) => {
      if (link) {
        axios
          .get(link)
          .then((res) => setMoc(res.data))
          .catch(() => displayError({ text: solutionErrorEnum.MOC }))
      } else {
        displayError({ text: solutionErrorEnum.LINK })
      }
    })
  }

  const getLink = (appId, linkId, action) =>
    contentBrokerSvcClient
      .relayContentMgt({
        method: 'GET',
        url: `managedappinfos/managedapp/${appId}/metadata/${linkId}/downloadlink`
      })
      .then((res) =>
        action(res?.status === 200 ? res.data?.downloadLink : null)
      )
      .catch(() => action(null))

  const setSolutions = (payload) => {
    dispatch({ type: SolutionActions.SET_SOLUTIONS, payload })
  }

  const setIcons = (payload) => {
    dispatch({ type: SolutionActions.SET_ICONS, payload })
  }

  const setMoc = (payload) => {
    dispatch({ type: SolutionActions.SET_MOC, payload })
  }

  return (
    <SolutionsContext.Provider
      value={{
        solutions: state.solutions,
        hasMoc: state.hasMoc,
        moc: state.moc,
        grantSvcClient: grantSvcClient,
        contentBrokerSvcClient: contentBrokerSvcClient,
        hasEntitlement,
        getSolutions,
        getMoc,
        setSolutions,
        setIcons,
        setMoc
      }}
    >
      {props.children}
    </SolutionsContext.Provider>
  )
}

export default SolutionsProvider
