import React, { useState } from 'react'
import {
  Button,
  ButtonGroup,
  IconCheckmark,
  IconChevronLeft,
  IconChevronRight,
  Stepper,
  StepsType,
  Modal
} from '@veneer/core'
import { StepperStatus } from 'components/policies/table/CreatePolicy'
import {
  FlexColumn,
  FlexRow,
  FlexRowWithSpace,
  FlexColumnGrow
} from 'styles/styles'
import EmailAddressItems from 'context/policies/dropboxItems/EmailAddressItems'
import {
  QuickSetsEnum,
  QuickSetsFileEnum,
  QuickSetsMessageEnum
} from './QuickSetsEnums'
import { EmailMessageEnum } from '../EmailMessage/EmailMessageEnums'
import AddQuickSetStep1 from './AddQuickSetStep1'
import AddQuickSetStep2 from './AddQuickSetStep2'
import AddQuickSetStep3 from './AddQuickSetStep3'
import AddQuickSetStep4 from './AddQuickSetStep4'
import { getFileNamePreview } from 'components/policies/modal/FileName'
import { getError } from 'common/utilities'
import PoliciesErrors from 'context/policies/PoliciesErrors'
import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'
enum QuickSetsSteps {
  BASIC_INFO,
  EMAIL_SETTINGS,
  COMMON_JOB,
  CONFIRMATION
}

const requiredIdsStep1: string[] = [
  QuickSetsEnum.TITLE,
  QuickSetsEnum.LOCATION,
  QuickSetsEnum.START
]
const requiredIdsStep2: string[] = [
  QuickSetsMessageEnum.RESTRICTIONS,
  EmailMessageEnum.FROM,
  EmailMessageEnum.TO,
  EmailMessageEnum.CC,
  EmailMessageEnum.BCC,
  EmailMessageEnum.DEF_NAME,
  EmailMessageEnum.DEF_FROM,
  EmailMessageEnum.SUBJECT,
  EmailMessageEnum.MESSAGE,
  EmailMessageEnum.TO + QuickSetsMessageEnum.ADDRESSES,
  EmailMessageEnum.CC + QuickSetsMessageEnum.ADDRESSES,
  EmailMessageEnum.BCC + QuickSetsMessageEnum.ADDRESSES
]
const requiredIdsStep3: string[] = [
  QuickSetsFileEnum.FILE_NAME,
  QuickSetsFileEnum.MEDIA_SIZE,
  QuickSetsFileEnum.SCAN_PLEX,
  QuickSetsFileEnum.COLOR_PREFERENCE,
  QuickSetsFileEnum.QUALITY,
  QuickSetsFileEnum.TYPE,
  QuickSetsFileEnum.RESOLUTION,
  QuickSetsFileEnum.COMPRESSION,
  QuickSetsFileEnum.COMPRESSION_BLACK,
  QuickSetsFileEnum.COMPRESSION_COLOR
]

export const errorKeys = [
  'common.errors.not-selected',
  'error_empty',
  'error_all_blank'
]

const QuickSetsModal = (props) => {
  const { edit, value, constraints, onClose, onChange } = props

  const getLocalized = (key: string, step = 0, params?): string =>
    props.getLocalized(
      step
        ? `policy.settings.attributes.quick-sets.device_settings.step-${step}.${key}`
        : key,
      params
    )

  const createAddQuickSetsSteps = (): StepsType[] => [
    {
      label: getLocalized('stepper-title', 1),
      status: StepperStatus.Current,
      step: 1
    },
    {
      label: getLocalized('stepper-title', 2),
      status: StepperStatus.Incomplete,
      step: 2
    },
    {
      label: getLocalized('stepper-title', 3),
      status: StepperStatus.Incomplete,
      step: 3
    },
    {
      label: getLocalized('stepper-title', 4),
      status: StepperStatus.Incomplete,
      step: 4
    }
  ]

  const checkConstraints = (x, obj = true) =>
    constraints
      ? !!constraints.isPolicyAttributeSettingSupported(
          PolicyAttributeEnum.Email_Message,
          PolicyAttributeEnum.Email_Message +
            (obj ? EmailMessageEnum.OBJECT : '.') +
            x
        )
      : true

  const preProcessValue = () => {
    if (!constraints) {
      return value
    }
    const quickSet = { ...value }
    const items = [
      { id: QuickSetsMessageEnum.RESTRICTIONS, obj: false },
      { id: EmailMessageEnum.FROM + EmailMessageEnum.EDITABLE, obj: true },
      { id: EmailMessageEnum.CC, obj: true },
      { id: EmailMessageEnum.CC + QuickSetsMessageEnum.ADDRESSES, obj: true },
      { id: EmailMessageEnum.CC + EmailMessageEnum.EDITABLE, obj: true },
      { id: EmailMessageEnum.BCC, obj: true },
      { id: EmailMessageEnum.BCC + QuickSetsMessageEnum.ADDRESSES, obj: true },
      { id: EmailMessageEnum.BCC + EmailMessageEnum.EDITABLE, obj: true },
      { id: EmailMessageEnum.SUBJECT + EmailMessageEnum.EDITABLE, obj: true },
      { id: EmailMessageEnum.MESSAGE + EmailMessageEnum.EDITABLE, obj: true },
      { id: QuickSetsMessageEnum.SIGN, obj: false },
      { id: QuickSetsMessageEnum.SIGN + EmailMessageEnum.EDITABLE, obj: false },
      { id: QuickSetsMessageEnum.ENCRYPT, obj: false },
      {
        id: QuickSetsMessageEnum.ENCRYPT + EmailMessageEnum.EDITABLE,
        obj: false
      }
    ]
    items.forEach((item) => {
      if (!checkConstraints(item.id, item.obj)) {
        delete quickSet[QuickSetsEnum.MESSAGE][item.id]
      }
    })
    return quickSet
  }

  const [stepItems, setStepItems] = useState(createAddQuickSetsSteps())
  const [currentStep, setCurrentStep] = useState(0)
  const [quickSetObject, setQuickSetObject] = useState(preProcessValue)
  const [requiredErrors, setRequiredErrors] = useState({})

  const instantStart =
    quickSetObject[QuickSetsEnum.START] === 'quick-sets.sets.start.instantly'

  const createModalFooter = () => {
    return (
      <FlexRowWithSpace className={'footerBorder fullWidth'}>
        <div>
          <Button appearance={'secondary'} onClick={onClose}>
            {getLocalized('common.cancel')}
          </Button>
        </div>
        <ButtonGroup>
          {currentStep > 0 && (
            <Button
              appearance={'secondary'}
              onClick={handleBackBtn}
              leadingIcon={<IconChevronLeft />}
              id={'backBtn'}
            >
              {getLocalized('common.back')}
            </Button>
          )}

          {currentStep < stepItems.length - 1 ? (
            <Button
              appearance={'primary'}
              onClick={handleNextBtn}
              trailingIcon={<IconChevronRight />}
              id={'nextBtn'}
            >
              {getLocalized('common.next')}
            </Button>
          ) : (
            <Button
              appearance={'primary'}
              onClick={handleSaveBtn}
              id={'saveBtn'}
            >
              {getLocalized(edit ? 'common.save' : 'common.add')}
            </Button>
          )}
        </ButtonGroup>
      </FlexRowWithSpace>
    )
  }

  const validateForm = () => {
    switch (currentStep) {
      case QuickSetsSteps.BASIC_INFO:
        return validateBasicInfo()

      case QuickSetsSteps.EMAIL_SETTINGS:
        return validateEmailSettings()

      case QuickSetsSteps.COMMON_JOB:
        return validateFileSettings()
    }
  }

  const validateBasicInfo = () => {
    const errors = { ...requiredErrors }
    requiredIdsStep1.forEach((id) => {
      if (!quickSetObject[id]) {
        errors[id] = {
          message: errorKeys[+(id === QuickSetsEnum.TITLE)] // 0 or 1
        }
      } else {
        delete errors[id]
      }
    })
    setRequiredErrors(errors)
    return Object.keys(errors).some((key) => requiredIdsStep1.includes(key))
  }

  const getOtherErrors = (id, msg) => {
    // Handle All addresses are blank error
    if (
      id === EmailMessageEnum.TO &&
      instantStart &&
      [EmailMessageEnum.TO, EmailMessageEnum.CC, EmailMessageEnum.BCC].every(
        (y) => msg[y] === 'message-recipient.blank'
      )
    ) {
      return { message: errorKeys[2] }
    }
    return msg[id] ? null : { message: errorKeys[0] } // default error
  }

  const validateEmailSettings = () => {
    const errors = { ...requiredErrors }
    const message = quickSetObject[QuickSetsEnum.MESSAGE]
    requiredIdsStep2
      .filter((id) =>
        checkConstraints(id, id !== QuickSetsMessageEnum.RESTRICTIONS)
      )
      .forEach((id) => {
        const getErrorObject = (x) => {
          switch (id) {
            case EmailMessageEnum.DEF_FROM:
              return getError(PoliciesErrors.errorsInvalidEmail, x)
            case EmailMessageEnum.DEF_NAME:
              return getError(PoliciesErrors.errorsDefFromName(constraints), x)
            case EmailMessageEnum.SUBJECT:
              return getError(PoliciesErrors.errorsSubject(constraints), x)
            case EmailMessageEnum.MESSAGE:
              return getError(PoliciesErrors.errorsMessage(constraints), x)
            case EmailMessageEnum.TO + QuickSetsMessageEnum.ADDRESSES:
            case EmailMessageEnum.CC + QuickSetsMessageEnum.ADDRESSES:
            case EmailMessageEnum.BCC + QuickSetsMessageEnum.ADDRESSES:
              return getError(PoliciesErrors.errorsInvalidEmailList, x)
            default:
              return getOtherErrors(id, message)
          }
        }
        const newError = getErrorObject(message[id])
        if (newError) {
          errors[id] = newError
        } else {
          delete errors[id]
        }
      })
    // Def_From & Def_Name are not always required
    if (
      message[EmailMessageEnum.FROM] !== EmailAddressItems.fromItems[0].value
    ) {
      delete errors[EmailMessageEnum.DEF_FROM]
      delete errors[EmailMessageEnum.DEF_NAME]
    }

    // To/CC/BCC Email Address is not always required
    const checkError = (id) => {
      if (message[id] !== EmailAddressItems.toItemsQs[2].value) {
        delete errors[id + QuickSetsMessageEnum.ADDRESSES]
      }
    }
    checkError(EmailMessageEnum.TO)
    checkError(EmailMessageEnum.CC)
    checkError(EmailMessageEnum.BCC)

    setRequiredErrors(errors)
    return Object.keys(errors).some((key) => requiredIdsStep2.includes(key))
  }

  const validateFileSettings = () => {
    const errors = { ...requiredErrors }
    const file = quickSetObject[QuickSetsEnum.FILE] || {}
    requiredIdsStep3.forEach((id) => {
      const error =
        id === QuickSetsFileEnum.FILE_NAME
          ? !getFileNamePreview(file).length
          : !file[id]
      if (error) {
        errors[id] = { message: errorKeys[0] }
      } else {
        delete errors[id]
      }
    })
    setRequiredErrors(errors)
    return Object.keys(errors).some((key) => requiredIdsStep3.includes(key))
  }

  const handleBackBtn = () => {
    const newStepItems = [...stepItems]
    newStepItems[currentStep - 1].status = StepperStatus.Current
    newStepItems[currentStep].status = StepperStatus.Incomplete
    setStepItems(newStepItems)
    setCurrentStep(currentStep - 1)
  }

  const handleNextBtn = () => {
    if (validateForm()) {
      return
    }
    const newStepItems = [...stepItems]
    const oldSelectedItem = newStepItems[currentStep]
    newStepItems[currentStep + 1].status = StepperStatus.Current
    oldSelectedItem.status = StepperStatus.Complete
    oldSelectedItem.step = <IconCheckmark filled />
    setRequiredErrors({})
    setStepItems(newStepItems)
    setCurrentStep(currentStep + 1)
  }

  const handleSaveBtn = () => {
    if (!Object.keys(requiredErrors).length) {
      onChange(quickSetObject)
      onClose()
    }
  }

  const getStepWiseComponent = () => {
    switch (currentStep) {
      case QuickSetsSteps.BASIC_INFO:
        return (
          <AddQuickSetStep1
            value={quickSetObject}
            onChange={(v) => setQuickSetObject((set) => ({ ...set, ...v }))}
            getLocalized={getLocalized}
            requiredErrors={requiredErrors}
            setRequiredErrors={setRequiredErrors}
          />
        )
      case QuickSetsSteps.EMAIL_SETTINGS:
        return (
          <AddQuickSetStep2
            value={quickSetObject[QuickSetsEnum.MESSAGE] || {}}
            instantStart={instantStart}
            constraints={constraints}
            checkConstraints={checkConstraints}
            onChange={(v) =>
              setQuickSetObject((set) => ({
                ...set,
                [QuickSetsEnum.MESSAGE]: { ...set[QuickSetsEnum.MESSAGE], ...v }
              }))
            }
            getLocalized={getLocalized}
            requiredErrors={requiredErrors}
            setRequiredErrors={setRequiredErrors}
          />
        )
      case QuickSetsSteps.COMMON_JOB:
        return (
          <AddQuickSetStep3
            value={quickSetObject[QuickSetsEnum.FILE] || {}}
            onChange={(v) =>
              setQuickSetObject((set) => ({
                ...set,
                [QuickSetsEnum.FILE]: { ...set[QuickSetsEnum.FILE], ...v }
              }))
            }
            getLocalized={getLocalized}
            requiredErrors={requiredErrors}
            setRequiredErrors={setRequiredErrors}
          />
        )
      case QuickSetsSteps.CONFIRMATION:
        return (
          <AddQuickSetStep4
            value={quickSetObject}
            getLocalized={getLocalized}
          />
        )
    }
  }

  return (
    <>
      <Modal
        className={'full-window-modal quick-set-modal-footer'}
        onClose={() => onClose()}
        closeOnBlur={false}
        show={true}
        footer={createModalFooter()}
        data-testid={'id-quick-sets-modal'}
      >
        <FlexRow className={'fullHeight'}>
          <FlexColumnGrow className={'sidebarWidth260 borderTopLeftRadius10'}>
            <aside className={'padding24'}>
              <h5 className={'paddingTop8'}>
                {getLocalized(edit ? 'edit-quick-set' : 'add-quick-set')}
              </h5>
              <Stepper
                steps={stepItems}
                appearance={'standard'}
                direction={'vertical'}
                interactive
                data-testid={'id-quick-sets-stepper'}
              />
            </aside>
          </FlexColumnGrow>
          <FlexColumn className={'quickset-modal-body'}>
            {getStepWiseComponent()}
          </FlexColumn>
        </FlexRow>
      </Modal>
    </>
  )
}

export default QuickSetsModal
