import React, { useState } from 'react'
import { Button, ButtonGroup, Modal } from '@veneer/core'
import LabelSelect from 'common/controls/labelSelect'

const HomeScreenAppsFolderSelectModal = (props) => {
  const { options, getLocalized, onClose, onChange, appName } = props
  const [value, setValue] = useState([])

  return (
    <Modal
      className={'small-policy-modal'}
      title={getLocalized('menu-to-folder')}
      data-testid={'id-folder-select-modal'}
      onClose={onClose}
      closeOnBlur={false}
      show={true}
      footer={
        <ButtonGroup>
          <Button
            id={'id-folder-select-modal-ok'}
            disabled={!value.toString()}
            onClick={() => {
              onChange(value.toString())
              onClose()
            }}
          >
            {getLocalized('common.move')}
          </Button>
          <Button
            id={'id-folder-select-modal-cancel'}
            appearance={'secondary'}
            onClick={onClose}
          >
            {getLocalized('common.cancel')}
          </Button>
        </ButtonGroup>
      }
    >
      <p className={'paddingBottom16'}>
        {getLocalized('select-folder', { appName })}
      </p>
      <LabelSelect
        id={'id-folder-select-modal-select'}
        className={'maxTextWidth'}
        label={getLocalized('folder-name')}
        placeholder={getLocalized('common.select-option')}
        options={options}
        value={value}
        onChange={(option) => setValue([option.value])}
      />
    </Modal>
  )
}

export default HomeScreenAppsFolderSelectModal
