import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Modal } from '@veneer/core'
import { TextBlack } from 'styles/styles'
import { getError } from 'common/utilities'
import PoliciesErrors from 'context/policies/PoliciesErrors'
import LabelTextBox from 'common/controls/labelTextBox'

const FaxSendTextModal = (props) => {
  const { onChange, onClose, getLocalized, textBoxParams, id } = props
  const [value, setValue] = useState(props.value)
  const [inputError, setInputError] = useState([])
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)

  const retrieveInputError = (errors, data, index) => {
    const retrieveError = getError(errors, data)
    setInputError((prevValue) => {
      const updatedItems = [...prevValue]
      updatedItems[index] = retrieveError
      return updatedItems
    })
    return retrieveError
  }

  useEffect(() => {
    setSaveButtonDisabled(JSON.stringify(props.value) === JSON.stringify(value))
  }, [props.value, value])

  const handleChange = (key, data, errors, index) => {
    retrieveInputError(errors, data, index)
    setValue((prevValue) => {
      return { ...prevValue, [key]: data }
    })
  }

  const textBoxComponent = (key: string, errors: PoliciesErrors, index) => {
    const hint = key + '_hint'
    const error = inputError[index]
    return (
      <div className={'paddingTop16'} key={key}>
        <LabelTextBox
          required={true}
          label={getLocalized(key)}
          value={value[key] || ''}
          placeholder={getLocalized(hint)}
          onChange={(value) => handleChange(key, value, errors, index)}
          id={key}
          error={!!error}
          helperText={getLocalized(error?.message || hint, error?.params)}
          className={'maxTextWidth'}
        />
      </div>
    )
  }

  const onSave = () => {
    const getErrors = textBoxParams.map((item, index) => {
      return retrieveInputError(item.errors, value[item.key], index)
    })
    if (!getErrors.some((ele) => ele !== null)) {
      onChange(value)
      onClose()
    }
  }

  return (
    <Modal
      footer={
        <ButtonGroup>
          <Button onClick={onSave} disabled={saveButtonDisabled}>
            {getLocalized('common.save')}
          </Button>
          <Button appearance={'secondary'} onClick={onClose}>
            {getLocalized('common.cancel')}
          </Button>
        </ButtonGroup>
      }
      onClose={onClose}
      closeOnBlur={false}
      show={true}
      className={'small-policy-modal'}
      title={getLocalized(`${id}-settings`)}
      data-testid={`id-fax-send-${id}-modal`}
    >
      <TextBlack>{getLocalized(`${id}-description-message`)}</TextBlack>

      <TextBlack className={'paddingTop16 bold'}>
        {getLocalized(`${id}-header`)}
      </TextBlack>

      {textBoxParams.map((item, index) => {
        return textBoxComponent(item.key, item.errors, index)
      })}
    </Modal>
  )
}

export default FaxSendTextModal
