import React, { useContext, useReducer } from 'react'
import {
  CollectionClient,
  DeviceCacheApiClient,
  Stack
} from '@jarvis/web-stratus-client'
import { CollectionsActions } from 'context/types'
import CollectionsContext from 'context/devices/collectionsContext'
import CollectionsReducer from 'context/devices/CollectionsReducer'
import ConfigContext from 'context/config/configContext'
import { errorProcessor } from 'context/policies/errorProcessor'

const CollectionsProvider = (props) => {
  const { t } = useContext(ConfigContext)
  const initialState = { devices: null, resources: null }
  const [state, dispatch] = useReducer(CollectionsReducer, initialState)
  const { dcStack, stack = Stack.pie, demoEnabled, authProvider } = props
  const collectionClient = new CollectionClient(stack, authProvider)
  const dcClient = new DeviceCacheApiClient(dcStack ?? stack, authProvider)
  const offset = 0
  const limit = -1
  const pageSize = 500

  const displayDevices = (payload) => {
    dispatch({ type: CollectionsActions.GET_DEVICES, payload })
  }

  const displayResources = (payload) => {
    dispatch({ type: CollectionsActions.GET_RESOURCES, payload })
  }

  const getDevices = async (displayError) => {
    if (demoEnabled) {
      displayDevices([])
      return
    }
    displayDevices(null)
    try {
      const res = await collectionClient.getCollections(
        offset,
        limit,
        undefined,
        'All',
        undefined,
        undefined,
        undefined,
        true
      )
      if (res?.status === 200 && res.data?.contents?.length) {
        const all = res.data.contents[0]
        if (!all.devices) {
          displayDevices([])
        } else {
          const pages = Math.ceil(all.devices / pageSize)
          const requests = Array.from(Array(pages)).map((_, i) =>
            collectionClient.getTypedCollectionById(
              all.id,
              'devices',
              i,
              pageSize,
              undefined,
              undefined,
              true
            )
          )
          const results = await Promise.all(requests)
          const devs = results.reduce(
            (acc, r) =>
              r?.status === 200 && r?.data?.contents?.length
                ? acc.concat(r.data.contents)
                : acc,
            []
          )
          displayDevices(devs)
        }
      }
    } catch (error) {
      displayDevices([])
      displayError(errorProcessor(error, t).error.message)
    }
  }

  const getResources = async (deviceId, displayError) => {
    if (demoEnabled) {
      displayResources({})
      return
    }
    displayResources(null)
    try {
      const res = await dcClient.getDeviceResources(deviceId)
      if (res?.status === 200) {
        displayResources(res.data || {})
      }
    } catch (error) {
      displayResources({})
      displayError(errorProcessor(error, t).error.message)
    }
  }

  return (
    <CollectionsContext.Provider
      value={{
        devices: state.devices,
        resources: state.resources,
        getResources,
        getDevices
      }}
    >
      {props.children}
    </CollectionsContext.Provider>
  )
}

export default CollectionsProvider
