import { PolicyAttributeEnum } from 'context/policies/PoliciesCategories'
import { Item } from '../PoliciesConfiguration'
import PolicyConstraints, {
  ConstraintsResourceEnum,
  ConstraintsSettingEnum
} from 'context/constraints/Constraints'

export enum CipherTypeEnum {
  RSA = 'rsa', // RSA
  ECDSA = 'ecdsa', // ECDSA
  ECDHE_RSA = 'ecdhe-rsa', // RSA
  DHE_RSA = 'dhe-rsa', // RSA
  CCM = 'ccm',
  GCM = 'gcm'
}

const cipher = (
  x: string,
  type: CipherTypeEnum,
  versions: number[] = [0, 1, 2],
  o?: string
) => ({
  value: `${PolicyAttributeEnum.Web_Encryption}.ciphers.${x}`,
  label: 'cipher-' + x,
  option: o ?? x.toUpperCase(),
  versions,
  type
})
const cipherTlsAll = (x: string, y: CipherTypeEnum) => cipher(x, y, [0, 1, 2])
const cipherTls1_2 = (x: string, y: CipherTypeEnum) => cipher(x, y, [2])
const cipherTls1_3 = (x: string, o: string, y: CipherTypeEnum) =>
  cipher(x, y, [3], o)

const strength = (x: string) => ({
  value: `${PolicyAttributeEnum.Web_Encryption}.strength.${x}`,
  label: 'strength-' + x
})

export default class WebEncryptionItems {
  static webEncryptionStrengths: Item[] = [
    strength('high'),
    strength('medium'),
    strength('low')
  ]

  static ciphers: Item[] = [
    cipherTlsAll('aes128-sha', CipherTypeEnum.RSA), // RFC3268, extending TLS v1.0
    cipherTls1_2('aes128-sha256', CipherTypeEnum.RSA), // TLS v1.2
    cipherTls1_2('aes128-gcm-sha256', CipherTypeEnum.GCM), // TLS v1.2
    cipherTlsAll('aes256-sha', CipherTypeEnum.RSA), // RFC3268, extending TLS v1.0
    cipherTls1_2('aes256-sha256', CipherTypeEnum.RSA), // TLS v1.2
    cipherTls1_2('aes256-gcm-sha384', CipherTypeEnum.GCM), // TLS v1.2
    cipherTlsAll('des-cbc3-sha', CipherTypeEnum.RSA), // SSL v3.0 / TLS v1.0
    cipherTlsAll('rc4-sha', CipherTypeEnum.RSA), // SSL v3.0 / TLS v1.0
    cipherTlsAll('rc4-md5', CipherTypeEnum.RSA), // SSL v3.0 / TLS v1.0
    cipherTlsAll('dhe-rsa-aes128-sha', CipherTypeEnum.DHE_RSA), // RFC3268, extending TLS v1.0
    cipherTls1_2('dhe-rsa-aes128-sha256', CipherTypeEnum.DHE_RSA), // TLS v1.2
    cipherTls1_2('dhe-rsa-aes128-gcm-sha256', CipherTypeEnum.GCM), // TLS v1.2
    cipherTlsAll('dhe-rsa-aes256-sha', CipherTypeEnum.DHE_RSA), // RFC3268, extending TLS v1.0
    cipherTls1_2('dhe-rsa-aes256-sha256', CipherTypeEnum.DHE_RSA), // TLS v1.2
    cipherTls1_2('dhe-rsa-aes256-gcm-sha384', CipherTypeEnum.GCM), // TLS v1.2
    cipherTlsAll('ecdhe-rsa-aes128-sha', CipherTypeEnum.ECDHE_RSA), // Elliptic curve
    cipherTls1_2('ecdhe-rsa-aes128-sha256', CipherTypeEnum.ECDHE_RSA), // TLS v1.2
    cipherTls1_2('ecdhe-rsa-aes128-gcm-sha256', CipherTypeEnum.ECDHE_RSA), // TLS v1.2
    cipherTlsAll('ecdhe-rsa-aes256-sha', CipherTypeEnum.ECDHE_RSA), // Elliptic curve
    cipherTls1_2('ecdhe-rsa-aes256-sha384', CipherTypeEnum.ECDHE_RSA), // TLS v1.2
    cipherTls1_2('ecdhe-rsa-aes256-gcm-sha384', CipherTypeEnum.ECDHE_RSA), // TLS v1.2
    cipherTls1_2('ecdhe-ecdsa-aes128-gcm-sha256', CipherTypeEnum.ECDSA), // TLS v1.2
    cipherTls1_2('ecdhe-ecdsa-aes256-gcm-sha384', CipherTypeEnum.ECDSA), // TLS v1.2
    cipherTlsAll('ecdhe-ecdsa-aes128-sha', CipherTypeEnum.ECDSA), // Elliptic curve
    cipherTlsAll('ecdhe-ecdsa-aes256-sha', CipherTypeEnum.ECDSA), // Elliptic curve
    cipherTls1_2('ecdhe-ecdsa-aes256-sha384', CipherTypeEnum.ECDSA), // TLS v1.2
    cipherTls1_2('ecdhe-ecdsa-aes128-sha256', CipherTypeEnum.ECDSA), // TLS v1.2
    cipherTls1_3(
      'tls-aes-128-ccm-sha256',
      'TLS_AES_128_CCM_SHA256',
      CipherTypeEnum.CCM
    ), // TLS v1.3
    cipherTls1_3(
      'tls-aes-128-gcm-sha256',
      'TLS_AES_128_GCM_SHA256',
      CipherTypeEnum.GCM
    ), // TLS v1.3
    cipherTls1_3(
      'tls-aes-256-gcm-sha384',
      'TLS_AES_256_GCM_SHA384',
      CipherTypeEnum.GCM
    ) // TLS v1.3
  ]

  static tlsVersions: Item[] = Array.from(Array(4)).map((_, i) => ({
    value: `${PolicyAttributeEnum.Web_Encryption}.tls-version.1-${i}`,
    label: 'tls-version_1-' + i,
    option: 'tls1_' + i
  }))

  static webEncryptionCiphers = (constraints?: PolicyConstraints): Item[] =>
    !constraints
      ? WebEncryptionItems.ciphers
      : constraints.filterSettingOptions(
          WebEncryptionItems.ciphers,
          ConstraintsResourceEnum.tlsConfig,
          ConstraintsSettingEnum.ciphers
        )

  static tlsMinVersions = (constraints?: PolicyConstraints): Item[] =>
    !constraints
      ? WebEncryptionItems.tlsVersions
      : constraints.filterSettingOptions(
          WebEncryptionItems.tlsVersions,
          ConstraintsResourceEnum.tlsConfig,
          ConstraintsSettingEnum.minProtocolVersion
        )

  static tlsMaxVersions = (constraints?: PolicyConstraints): Item[] =>
    !constraints
      ? WebEncryptionItems.tlsVersions
      : constraints.filterSettingOptions(
          WebEncryptionItems.tlsVersions,
          ConstraintsResourceEnum.tlsConfig,
          ConstraintsSettingEnum.maxProtocolVersion
        )

  // Helpers

  static isRsa = (type: CipherTypeEnum) =>
    [
      CipherTypeEnum.RSA,
      CipherTypeEnum.DHE_RSA,
      CipherTypeEnum.ECDHE_RSA
    ].includes(type)
  static isEcdsa = (type: CipherTypeEnum) => CipherTypeEnum.ECDSA === type
}
