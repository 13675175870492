import React from 'react'
import { TextBox } from '@veneer/core'
import { TextBlack, PreviewValue } from 'styles/styles'
import { MOC } from 'common/mocParser/MocUtils'

const AppDeploymentText = (props) => {
  const {
    fId,
    sId,
    set,
    error,
    preview,
    disabled,
    helperText,
    required,
    componentValue,
    updateStateValue
  } = props

  return (
    <>
      <TextBlack
        disabled={disabled}
        required={required}
        className={'marginBottom4'}
      >
        {set[MOC.LABEL]}
      </TextBlack>
      {preview ? (
        <PreviewValue>{componentValue(fId, sId)}</PreviewValue>
      ) : (
        <TextBox
          id={set[MOC.NAME]}
          type={set[MOC.TYPE] === MOC.TYPES.PASSWORD ? set[MOC.TYPE] : 'text'}
          placeholder={Object.values(set[MOC.OPTIONS][0])?.[0]?.toString()}
          value={componentValue(fId, sId)}
          onChange={(value) => {
            const valueToSet = {
              [fId]: { [sId]: { [MOC.VALUES]: [{ [sId]: value }] } }
            }
            updateStateValue(valueToSet)
          }}
          error={error}
          helperText={helperText}
          className={'maxTextWidth'}
          disabled={disabled}
        />
      )}
    </>
  )
}
export default AppDeploymentText
