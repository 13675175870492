import React, { useContext, memo } from 'react'
import configContext from 'context/config/configContext'
import PolicyHeaderRight from 'components/policies/settings/attributes/header/right'
import { FlexRow, FlexRowWithSpace } from 'styles/styles'
import 'styles/global.scss'
import './index.scss'

const PolicySettingsCard = (props) => {
  const { t } = useContext(configContext)
  const { errors, warnings } = props
  return (
    <FlexRowWithSpace className={'policyCard alignCenter'}>
      <p className={'policyCardTitle'}>{t('policy.details.card.title')}</p>
      {errors !== undefined && warnings !== undefined ? (
        <FlexRow className={'alignCenter'}>
          <div className={'policyCardRight marginRight8'}>
            {t('policy.details.card.needsAttention')}
          </div>
          <PolicyHeaderRight errors={errors} warnings={warnings} />
        </FlexRow>
      ) : null}
    </FlexRowWithSpace>
  )
}

export default memo(PolicySettingsCard)
