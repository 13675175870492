import React from 'react'
import { Card, ColumnIndexTypes, Table, Scrollbar } from '@veneer/core'
import { FlexColumn, cssFullHeight, PreviewLabel } from 'styles/styles'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'
import { getFileNamePreview } from 'components/policies/modal/FileName'
import { EmailMessageEnum } from '../EmailMessage/EmailMessageEnums'
import {
  QuickSetsEnum,
  QuickSetsFileEnum,
  QuickSetsMessageEnum
} from './QuickSetsEnums'
import EmailAddressItems from 'context/policies/dropboxItems/EmailAddressItems'
import FileSettingItems from 'context/policies/dropboxItems/FileSettingItems'
import PrintMediaItems from 'context/policies/dropboxItems/PrintMediaItems'
import { FileNameEnum } from '../FileName/FileNameEnums'
import QuickSetsItems from './QuickSetsItems'
import { TABLE_CONSTANTS, isTrueString } from 'common/utilities'

const BASIC = 'basic'
const MESSAGE = 'message'
const FILE = 'file'

const columnId = 'id'
const columnOption = 'option'
const columnValue = 'value'
const columnEditable = 'editable'
const attrKey = (attr, key) =>
  `policy.settings.attributes.${attr}.device_settings.${key}`
const userEditable = 'user-editable'

const AddQuickSetStep4 = (props) => {
  const { value, getLocalized } = props

  const getLocalizedMessage = (key) =>
    getLocalized(attrKey('email-message', key))
  const getLocalizedFile = (key) => getLocalized(attrKey('email-file', key))
  const getLocalizedFileName = (key, prefix = 'file-name-', params?) =>
    getLocalized(
      `policy.settings.file-name-settings.${prefix}${key}`,
      0,
      params
    )
  const getLocalizedBoolean = (val) =>
    getLocalized(isTrueString(val) ? 'common.on' : 'common.off')

  const generateTableColumns = (editable?) => {
    const index: ColumnIndexTypes = 'hidden'
    const columns = [
      { id: columnId, label: 'id', index },
      { id: columnOption, label: getLocalizedMessage('option-name') },
      { id: columnValue, label: getLocalizedMessage('value') }
    ]
    return editable
      ? [
          ...columns,
          { id: columnEditable, label: getLocalizedMessage(userEditable) }
        ]
      : columns
  }

  const getItemValue = (items, val, localization) => {
    const found = items.find((x) => x.value === val)
    return found ? localization(found.label) : TABLE_CONSTANTS.NO_DATA
  }

  const itemTextbox = (val, id, localization = getLocalized, editableId?) => {
    return {
      [columnId]: id,
      [columnOption]: localization(id),
      [columnValue]: val[id] ? val[id].toString() : '',
      [columnEditable]:
        editableId && val[editableId]
          ? getLocalizedBoolean(val[editableId])
          : TABLE_CONSTANTS.NO_DATA
    }
  }

  const itemFileName = (value) => {
    const name = value[FileNameEnum.NAME] || ''
    return {
      [columnId]: FileNameEnum.NAME,
      [columnOption]: getLocalizedFileName('default-file-name', ''),
      [columnValue]: value[FileNameEnum.EDITABLE]
        ? getLocalizedFileName('default-file-name-editable', '', {
            name,
            editable: getLocalizedFileName(userEditable, '')
          })
        : name
    }
  }

  const itemFileNamePreview = (value) => {
    return {
      [columnId]: FileNameEnum.NAME,
      [columnOption]: getLocalizedFileName('preview'),
      [columnValue]: getFileNamePreview(
        value,
        getLocalized,
        value[QuickSetsFileEnum.TYPE]
      )
    }
  }

  const itemDropdown = (items, val, id, localization, editableId?) => {
    return {
      [columnId]: id,
      [columnOption]: localization(id),
      [columnValue]: getItemValue(
        items,
        val[id],
        id === QuickSetsEnum.START || id === QuickSetsEnum.LOCATION
          ? localization
          : getLocalized
      ),
      [columnEditable]:
        editableId && val[editableId]
          ? getLocalizedBoolean(val[editableId])
          : TABLE_CONSTANTS.NO_DATA
    }
  }

  const itemCheckbox = (val, id, localization = getLocalized, editableId?) => {
    return {
      [columnId]: id,
      [columnOption]: localization(id),
      [columnValue]: getLocalizedBoolean(val[id]),
      [columnEditable]:
        editableId && val[editableId]
          ? getLocalizedBoolean(val[editableId])
          : TABLE_CONSTANTS.NO_DATA
    }
  }

  const getBasicTableData = () => {
    return [
      itemTextbox(value, QuickSetsEnum.TITLE),
      itemDropdown(
        QuickSetsItems.locationOptions,
        value,
        QuickSetsEnum.LOCATION,
        (x) => getLocalized(x, 1)
      ),
      itemTextbox(value, QuickSetsEnum.DESCRIPTION),
      itemDropdown(
        QuickSetsItems.startOptions,
        value,
        QuickSetsEnum.START,
        (x) => getLocalized(x, 1)
      )
    ]
  }

  const generateMessageTableData = (value) => {
    const items = []

    if (QuickSetsMessageEnum.RESTRICTIONS in value) {
      items.push(
        itemDropdown(
          EmailAddressItems.restrictionItems,
          value,
          QuickSetsMessageEnum.RESTRICTIONS,
          getLocalizedMessage
        )
      )
    }

    const fromItems = [
      itemDropdown(
        EmailAddressItems.fromItems,
        value,
        EmailMessageEnum.FROM,
        getLocalizedMessage,
        EmailMessageEnum.FROM + EmailMessageEnum.EDITABLE
      ),
      itemTextbox(value, EmailMessageEnum.DEF_FROM, getLocalizedMessage),
      itemTextbox(value, EmailMessageEnum.DEF_NAME, getLocalizedMessage),
      itemDropdown(
        EmailAddressItems.toItemsQs,
        value,
        EmailMessageEnum.TO,
        getLocalizedMessage,
        EmailMessageEnum.TO + EmailMessageEnum.EDITABLE
      ),
      itemTextbox(
        value,
        EmailMessageEnum.TO + QuickSetsMessageEnum.ADDRESSES,
        (x) => getLocalized(x, 4)
      )
    ]
    fromItems.forEach((x) => items.push(x))

    if (EmailMessageEnum.CC in value) {
      items.push(
        itemDropdown(
          EmailAddressItems.toItemsQs,
          value,
          EmailMessageEnum.CC,
          getLocalizedMessage,
          EmailMessageEnum.CC + EmailMessageEnum.EDITABLE
        )
      )
      items.push(
        itemTextbox(
          value,
          EmailMessageEnum.CC + QuickSetsMessageEnum.ADDRESSES,
          (x) => getLocalized(x, 4)
        )
      )
    }
    if (EmailMessageEnum.BCC in value) {
      items.push(
        itemDropdown(
          EmailAddressItems.toItemsQs,
          value,
          EmailMessageEnum.BCC,
          getLocalizedMessage,
          EmailMessageEnum.BCC + EmailMessageEnum.EDITABLE
        )
      )
      items.push(
        itemTextbox(
          value,
          EmailMessageEnum.BCC + QuickSetsMessageEnum.ADDRESSES,
          (x) => getLocalized(x, 4)
        )
      )
    }

    const subjMsgItems = [
      itemTextbox(
        value,
        EmailMessageEnum.SUBJECT,
        getLocalizedMessage,
        EmailMessageEnum.SUBJECT + EmailMessageEnum.EDITABLE
      ),
      itemTextbox(
        value,
        EmailMessageEnum.MESSAGE,
        getLocalizedMessage,
        EmailMessageEnum.MESSAGE + EmailMessageEnum.EDITABLE
      )
    ]
    subjMsgItems.forEach((x) => items.push(x))

    if (QuickSetsMessageEnum.SIGN in value) {
      items.push(
        itemCheckbox(
          value,
          QuickSetsMessageEnum.SIGN,
          getLocalizedMessage,
          QuickSetsMessageEnum.SIGN + EmailMessageEnum.EDITABLE
        )
      )
    }
    if (QuickSetsMessageEnum.ENCRYPT in value) {
      items.push(
        itemCheckbox(
          value,
          QuickSetsMessageEnum.ENCRYPT,
          getLocalizedMessage,
          QuickSetsMessageEnum.ENCRYPT + EmailMessageEnum.EDITABLE
        )
      )
    }

    return items
  }

  const getFileTableData = (value) => {
    return [
      itemTextbox(value, FileNameEnum.PREFIX, getLocalizedFileName),
      itemFileName(value),
      itemTextbox(value, FileNameEnum.SUFFIX, getLocalizedFileName),
      itemFileNamePreview(value),
      itemDropdown(
        PrintMediaItems.mediaSizes(),
        value,
        QuickSetsFileEnum.MEDIA_SIZE,
        getLocalizedFile
      ),
      itemDropdown(
        FileSettingItems.scanPlexItems,
        value,
        QuickSetsFileEnum.SCAN_PLEX,
        getLocalizedFile
      ),
      itemDropdown(
        FileSettingItems.fileColorPrefItems,
        value,
        QuickSetsFileEnum.COLOR_PREFERENCE,
        getLocalizedFile
      ),
      itemDropdown(
        FileSettingItems.fileQualityItems,
        value,
        QuickSetsFileEnum.QUALITY,
        getLocalizedFile
      ),
      itemDropdown(
        FileSettingItems.fileResolutionItems,
        value,
        QuickSetsFileEnum.RESOLUTION,
        getLocalizedFile
      ),
      itemDropdown(
        FileSettingItems.fileTypeItems,
        value,
        QuickSetsFileEnum.TYPE,
        getLocalizedFile
      ),
      itemDropdown(
        FileSettingItems.fileCompressionBlackItems,
        value,
        QuickSetsFileEnum.COMPRESSION_BLACK,
        getLocalizedFile
      ),
      itemDropdown(
        FileSettingItems.fileCompressionColorItems,
        value,
        QuickSetsFileEnum.COMPRESSION_COLOR,
        getLocalizedFile
      ),
      itemDropdown(
        FileSettingItems.fileCompressionItems,
        value,
        QuickSetsFileEnum.COMPRESSION,
        getLocalizedFile
      ),
      itemCheckbox(value, QuickSetsFileEnum.PDF_ENCRYPTION, getLocalizedFile),
      itemCheckbox(
        value,
        QuickSetsFileEnum.BLANK_PAGE_SUPPRESSION,
        getLocalizedFile
      )
    ]
  }

  const twoColumnPreferences = {
    width: [
      { columnId: columnOption, width: 240 },
      { columnId: columnValue, width: 360 }
    ]
  }

  const threeColumnPreferences = {
    width: [
      { columnId: columnOption, width: 240 },
      { columnId: columnValue, width: 240 },
      { columnId: columnEditable, width: 120 }
    ]
  }

  const multipleTablesData = {
    [BASIC]: {
      tableHeader: getLocalized('stepper-title', 1),
      tableColumns: generateTableColumns(),
      tableData: getBasicTableData(),
      tablePreferences: twoColumnPreferences
    },
    [MESSAGE]: {
      tableHeader: getLocalized('stepper-title', 2),
      tableColumns: generateTableColumns(true),
      tableData: generateMessageTableData(value[QuickSetsEnum.MESSAGE]),
      tablePreferences: threeColumnPreferences
    },
    [FILE]: {
      tableHeader: getLocalized('stepper-title', 3),
      tableColumns: generateTableColumns(),
      tableData: getFileTableData(value[QuickSetsEnum.FILE]),
      tablePreferences: twoColumnPreferences
    }
  }

  const generateTable = (id: string, className = 'marginBottom16') => {
    const { tableHeader, tableColumns, tableData, tablePreferences } =
      multipleTablesData[id]
    return (
      <>
        <PreviewLabel>{tableHeader}</PreviewLabel>
        <div className={className}>
          <Table
            columns={tableColumns}
            data={tableData}
            className={'widthColAuto'}
            data-testid={`${id}-table`}
            preferences={tablePreferences}
            i18n={Retrievei18nItems()}
          />
        </div>
      </>
    )
  }

  const generateCardContent = () => {
    return (
      <Scrollbar customStyle={cssFullHeight}>
        <FlexColumn className={'padding1224'}>
          {generateTable(BASIC)}
          {generateTable(MESSAGE)}
          {generateTable(FILE, '')}
        </FlexColumn>
      </Scrollbar>
    )
  }

  return (
    <>
      <h5 className={'step-heading'}>{getLocalized('header', 4)}</h5>
      <p className={'contentPadding'}>{getLocalized('step-description', 4)}</p>
      <div className={'quick-set-card-height'}>
        <Card
          id={'add-quick-set-step-4'}
          content={generateCardContent()}
          border={'dropShadow'}
          customStyle={{ height: '100%', padding: '12px 0 12px 0' }}
        />
      </div>
    </>
  )
}

export default AddQuickSetStep4
