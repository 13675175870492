import React, { useState, useEffect } from 'react'
import { Button, ButtonGroup, Pagination, Modal } from '@veneer/core'
import { FlexRow, TextBlack } from 'styles/styles'

const HomeScreenAppsChangePageModal = (props) => {
  const { value, count, getLocalized, onClose, onChange, appName } = props
  const [page, setPage] = useState(value)

  useEffect(() => {
    setPage(value)
  }, [value])

  return (
    <Modal
      className={'small-policy-modal'}
      title={getLocalized('menu-change-page')}
      data-testid={'id-change-page-modal'}
      onClose={onClose}
      closeOnBlur={false}
      show={true}
      footer={
        <ButtonGroup>
          <Button
            id={'id-change-page-modal-ok'}
            disabled={page === value}
            onClick={() => {
              onChange(page)
              onClose()
            }}
          >
            {getLocalized('common.save')}
          </Button>
          <Button
            id={'id-change-page-modal-cancel'}
            appearance={'secondary'}
            onClick={onClose}
          >
            {getLocalized('common.cancel')}
          </Button>
        </ButtonGroup>
      }
    >
      <TextBlack className={'paddingBottom16'}>
        {getLocalized('change-page-1', { appName })}
        <br />
        {getLocalized('change-page-2')}
        <br />
        {getLocalized('change-page-3')}
      </TextBlack>
      <TextBlack className={'paddingBottom8'}>
        {getLocalized('page-number')}
      </TextBlack>
      <FlexRow className={'alignCenter'}>
        <Button
          small
          appearance={page ? 'ghost' : 'primary'}
          onClick={() => setPage(0)}
        >
          {getLocalized('first-page')}
        </Button>
        <Pagination
          currentPage={page}
          onPageChange={(p) => setPage(p)}
          pageSize={1}
          paginationNavigationSize={7}
          totalItems={count}
        />
        <Button
          small
          appearance={page > count ? 'primary' : 'ghost'}
          onClick={() => setPage(count + 1)}
        >
          {getLocalized('last-page')}
        </Button>
      </FlexRow>
    </Modal>
  )
}

export default HomeScreenAppsChangePageModal
