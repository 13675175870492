import { Item } from '../PoliciesConfiguration'
import { PolicyAttributeEnum } from '../PoliciesCategories'

const item = (x, y) => ({
  value: `${PolicyAttributeEnum.System_Logging}.${x}.${y}`,
  label: `${x}-${y}`
})

const protocol = (x) => item('protocol', x)
const severity = (x) => item('severity', x)
const facility = (x) => item('facility', x)

export default class SyslogItems {
  static protocols: Item[] = [protocol('tcp'), protocol('udp'), protocol('tls')]

  static severities: Item[] = [
    severity('emergency'),
    severity('alert'),
    severity('critical'),
    severity('error'),
    severity('warning'),
    severity('notice'),
    severity('informational'),
    severity('debug')
  ]

  static facilities: Item[] = [
    facility('lpr'),
    facility('local-0'),
    facility('local-1'),
    facility('local-2'),
    facility('local-3'),
    facility('local-4'),
    facility('local-5'),
    facility('local-6'),
    facility('local-7')
  ]
}
