import React, {
  useContext,
  memo,
  useState,
  useEffect,
  useMemo,
  useRef
} from 'react'
import settingsContext from 'context/settings/settingsContext'
import configContext from 'context/config/configContext'
import policiesContext from 'context/policies/policiesContext'
import { Button, ButtonGroup, Table } from '@veneer/core'
import { ColumnIndexTypes } from '@veneer/core/dist/scripts/table'
import { FlexRow, FlexColumn, TextBlack, PreviewLabel } from 'styles/styles'
import 'styles/global.scss'
import { ArrayAttributeDescription } from 'context/policies/PoliciesConfiguration'
import PoliciesHelper from 'context/policies/PoliciesHelper'
import PreviewItem from 'components/policies/settings/attributes/device/previewItem'
import Retrievei18nItems from 'common/utilityItems/Retrievei18nItems'
import HelpButton from '../../HelpButton'
import { getRowSelectAllState, generateBase64Value } from 'common/utilities'

export enum CertificateUploadEnum {
  NAME = 'name',
  ID = 'id'
}

const CertificateUploadControl = (props) => {
  const description: ArrayAttributeDescription = props.description
  const {
    compliance,
    attributes,
    data: { deviceSettings },
    onAttributeChange
  } = props
  const { attribute } = description

  const { tt } = useContext(configContext)
  const { showError, fleetMgtSvcClient } = useContext(policiesContext)
  const { isEnabled, addDisabled, removeDisabled } = useContext(settingsContext)
  const fileInputRef = useRef(null)
  const [selectedCount, setSelectedCount] = useState(0)
  const [tableData, setTableData] = useState([])
  const [rowSelectAllState, setRowSelectAllState] = useState(undefined)

  const value = useMemo(
    () => PoliciesHelper.getData(description, deviceSettings),
    [deviceSettings]
  )

  const enabled = isEnabled(attribute)

  const getLocalized = (key: string, params?): string =>
    tt(props.localizationPath, key, params)

  useEffect(() => {
    const selected = tableData.filter((row) => row.rowConfig.selected).length
    setSelectedCount(selected)
    setRowSelectAllState(getRowSelectAllState(selected, tableData.length))
  }, [tableData])

  useEffect(() => {
    setTableData(value ? value.map((x) => tableItem(x)) : [])
  }, [value])

  useEffect(() => {
    if (attributes) {
      PoliciesHelper.update(
        description,
        value,
        (ids, value) => (value ? removeDisabled(ids) : addDisabled(ids)),
        props.onSettingsChanges,
        attributes
      )
    }
  }, [value, attributes])

  const handleAddCertificate = () => fileInputRef.current.click()

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files
    if (!selectedFiles || selectedFiles.length === 0) return

    const loadAndRecordPromises = []
    for (const selectedFile of selectedFiles) {
      const promise = new Promise((resolve) => {
        const reader = new FileReader()
        reader.onload = async function (certificate) {
          try {
            const res = await fleetMgtSvcClient.uploadFile(
              selectedFile.name,
              'CERTIFICATE',
              generateBase64Value(certificate)
            )
            if (res.status && res.status === 200) {
              const newRecord = {
                [CertificateUploadEnum.ID]: res.data.id,
                [CertificateUploadEnum.NAME]: res.data.filename
              }
              const recordArranged = tableItem(newRecord)
              resolve(recordArranged)
            }
          } catch (error) {
            showError(error)
          }
        }
        reader.readAsDataURL(selectedFile)
      })
      loadAndRecordPromises.push(promise)
    }
    Promise.all(loadAndRecordPromises).then((records) => {
      setData([...tableData, ...records])
    })
    fileInputRef.current.value = ''
  }

  const setData = (newTableData) => {
    const settings = [...deviceSettings]
    const newValue = newTableData.map((row) => row.rowConfig.item)
    PoliciesHelper.setData(description, settings, newValue)
    onAttributeChange({ ...props.data, deviceSettings: settings })
  }

  const handleRowSelect = (event, rowId) => {
    const { checked } = event.target
    const rowRecord = tableData.find(({ id }) => id === rowId)
    if (rowRecord) {
      rowRecord.rowConfig.selected = checked
      setTableData([...tableData])
    }
  }

  const onRemove = () => {
    const data = tableData.filter((rowRecord) => !rowRecord.rowConfig.selected)
    setData(data)
  }

  const handleSelectAllPageItems = (event) => {
    const { checked } = event.target
    setTableData(
      tableData.map((row) => {
        row.rowConfig.selected = checked
        return row
      })
    )
  }

  const tableHeader = (text) =>
    !onAttributeChange ? (
      <PreviewLabel>{text}</PreviewLabel>
    ) : (
      <FlexRow className={'alignCenter marginBottom12'}>
        <TextBlack disabled={!enabled}>{text}</TextBlack>
        <FlexRow className={'paddingLeft4'}>
          <HelpButton
            enabled={enabled}
            description={getLocalized('certificate_help')}
          />
        </FlexRow>
      </FlexRow>
    )

  const tableItem = (item) => {
    const rowId = item[CertificateUploadEnum.ID]
    const selected = tableData.some(
      (record) =>
        record[CertificateUploadEnum.ID] === rowId && record.rowConfig.selected
    )
    return {
      [CertificateUploadEnum.ID]: item[CertificateUploadEnum.ID],
      [CertificateUploadEnum.NAME]: item[CertificateUploadEnum.NAME],
      rowConfig: { item, selected }
    }
  }

  const tableColumns = useMemo(() => {
    const index: ColumnIndexTypes = 'hidden'
    return [
      { id: CertificateUploadEnum.ID, label: CertificateUploadEnum.ID, index },
      {
        id: CertificateUploadEnum.NAME,
        label: getLocalized('name')
      }
    ]
  }, [])

  return (
    <>
      <FlexColumn>
        {tableHeader(getLocalized('certificate-file'))}
        {onAttributeChange && (
          <FlexRow className={'marginBottom12 alignCenter'}>
            <ButtonGroup>
              <input
                type={'file'}
                ref={fileInputRef}
                onChange={handleFileChange}
                className={'displayNone'}
                accept={'.cer'}
                multiple
              />
              <Button
                onClick={handleAddCertificate}
                id={'addCertificate'}
                appearance={'secondary'}
                disabled={!enabled}
              >
                {getLocalized('common.add')}
              </Button>
              <Button
                onClick={onRemove}
                id={'removeCertificate'}
                appearance={'secondary'}
                disabled={!enabled || selectedCount < 1}
              >
                {getLocalized('common.remove')}
              </Button>
            </ButtonGroup>
            <TextBlack className={'marginLeft12'} disabled={!enabled}>
              {getLocalized('common.n-files-added', {
                count: tableData.length
              })}
            </TextBlack>
          </FlexRow>
        )}
        <Table
          columns={tableColumns}
          data={tableData}
          onSelect={handleRowSelect}
          onSelectAllPageItems={handleSelectAllPageItems}
          rowSelectAllState={rowSelectAllState}
          rowSelector={
            onAttributeChange && enabled ? 'multiSelection' : undefined
          }
          preferences={{
            width: [{ columnId: CertificateUploadEnum.NAME, width: 200 }]
          }}
          className={'widthColAuto'}
          i18n={Retrievei18nItems()}
        />
        {compliance && (
          <PreviewItem compliance={compliance} className={'marginTop4'} />
        )}
      </FlexColumn>
    </>
  )
}

export default memo(CertificateUploadControl)
